import "minimal-polyfills/Object.fromEntries";
import { getKcContext } from "keycloakify";

/** It's the Keycloak context, it is undefined unless we are on Keycloak, rendering the login pages. */
export const { kcContext } = getKcContext({
    // "mockPageId": "register-user-profile.ftl",
    /**
     * Customize the simulated kcContext that will let us
     * dev the page outside keycloak (with auto-reload)
     */
    "mockData": [
        {
            "pageId": "login.ftl",
            "social": {
                "providers": [
                    {
                        "displayName": "Google",
                    },
                    {
                        "displayName": "Apple",
                    },
                ],
            },
        },
        {
            "pageId": "register-user-profile.ftl",
            "profile": {
                "attributes": [
                    {
                        "validators": {
                            "pattern": {
                                "pattern": "^[a-zA-Z0-9]+$",
                                "ignore.empty.value": true,
                                // eslint-disable-next-line no-template-curly-in-string
                                "error-message": "${alphanumericalCharsOnly}",
                            },
                        },
                        "value": undefined,
                        "name": "username",
                    },
                    {
                        "validators": {
                            "pattern": {
                                /* spell-checker: disable */
                                "pattern": ".*",
                                /* spell-checker: enabled */
                            },
                        },
                        "name": "email",
                    },
                ],
            },
        },
        { "pageId": "login-reset-password.ftl" },
        { "pageId": "login-update-password.ftl" },
    ],
});

export type KcContext = NonNullable<typeof kcContext>;
