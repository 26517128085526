const dictionary = {
    exportMinio: {
        fr: "Accéder à minio en dehors du datalab : ",
        en: "Access minio from outside the datalab: ",
    },
    exportKub: {
        fr: "Accéder à kubernetes en dehors du datalab : ",
        en: "Access kubernetes from outside the datalab: ",
    },
    pwdTitle: {
        fr: "Mot de passe pour vos services",
        en: "Password for your services",
    },
    hello: {
        fr: "Bonjour",
        en: "Hello",
    },
    user: {
        fr: "Utilisateur",
        en: "User",
    },
    onyxiaProfile: {
        fr: "Profil Onyxia",
        en: "Onyxia Profile",
    },
    gitUserName: {
        fr: "Nom d'utilisateur pour Git",
        en: "User name for Git",
    },
    gitUserEmail: {
        fr: "Adresse mail pour Git",
        en: "Email for git",
    },
    gitCacheDuration: {
        fr: "Durée de préservation de votre mot de passe dans le cache git (en secondes - 0 valant désactivation du cache)",
        en: "Git credentials cache duration (in seconds - 0 for no cache)",
    },
    kaggleApiToken: {
        fr: "API token de Kaggle",
        en: "Kaggle API token",
    },
    minioLoginInfo: {
        fr: "Identifiants Minio (S3)",
        en: "Minio (S3) Login Details",
    },
    minioLoginExplanation: {
        fr: "Ces identifiants vous permettent d'accéder à vos fichiers. Ils sont valables jusqu'au ",
        en: "This credentials allow you to access your files. They are available until ",
    },
    k8sLoginExplanation: {
        fr: "Ces identifiants vous permettent d'accéder au cluster kubernetes.",
        en: "This credentials allow you to access the Kubernetes cluster",
    },
    activateBetatest: {
        fr: "Activer le mode avancé (béta-testeur)",
        en: "Activate advanced mode (beta-test)",
    },
    oidcToken: {
        fr: "Jeton OIDC",
        en: "OIDC Token",
    },
    minioAccessKey: {
        fr: "Access Key",
        en: "Access Key",
    },
    minioSecretAccessKey: {
        fr: "Secret Access Key",
        en: "Secret Access Key",
    },
    minioSessionToken: {
        fr: "Session Token",
        en: "Session Token",
    },
    minioEndpoint: {
        fr: "S3 Endpoint",
        en: "S3 Endpoint",
    },
};

export default dictionary;
