const dictionary = {
    guidedTourTitle: {
        fr: "Visite guidée",
        en: "Guided tour",
    },
    guidedTourWelcomeTitle: {
        fr: "Bienvenue sur la visite guidée d'Onyxia !",
        en: "Welcome to the guided tour of Onyxia",
    },
    guidedTourWelcomeText1: {
        fr:
            "Nous sommes heureux de vous présenter le portail du datalab. " +
            "Parcourons ensemble ses principales fonctionnalités.",
        en:
            "We are very pleased to introduce you the datalab. " +
            "Let's have a short presentation of the functionalities.",
    },
    guidedTourWelcomeText2: {
        fr: "Bonne visite !",
        en: "Enjoy the tour!",
    },
    guidedTourAuthenticationTitle: {
        fr: "Authentification",
        en: "Authentication",
    },
    guidedTourAuthenticationText1: {
        fr: "La plupart des services nécessitent de savoir qui vous êtes.",
        en: "Most services need to know who you are.",
    },
    guidedTourAuthenticationText2: {
        fr:
            "Une fois connecté, vous pouvez accéder à vos informations et jetons d'accès sur " +
            "la page Mon compte.",
        en:
            "Once connected, you can access to your personal information and access token on " +
            "My account page.",
    },
    guidedTourAuthenticationText3: {
        fr: "Commençons par la visite des services partagés.",
        en: "Let's start with the shared services.",
    },
    guidedTourSharedServicesTitle: {
        fr: "Les services partagés",
        en: "Shared services",
    },
    guidedTourVignette1Text1: {
        fr:
            "Cette page vous présente l'ensemble des services partagés à votre disposition. " +
            "Un service partagé est un service unique servant à l'ensemble des utilisateurs du datalab. " +
            "Il s'agit d'applications collaboratives, telles qu'une forge logicielle, des services " +
            "transverses métier, un service de géocodage  ou encore des services techniques " +
            "comme le stockage de données ou le service d'authentification.",
        en:
            "This page presents you the set of shared services offered on the datalab. " +
            "A shared service is a unique instance serving all datalab users. " +
            "They may be groupwares, such as a Git-repository hosting service, statistical business " +
            "tools, such as a geocoding service or technical shared services, such as data storage or " +
            "authentication services.",
    },
    guidedTourVignette1Text2: {
        fr:
            "Le catalogue s'enrichit au fil du temps, et vous pouvez dès à présent faire vos " +
            "propositions sur le service de messagerie instantanée.",
        en:
            "The shared services catalog expands progressively, and you are invited to make suggestions " +
            "on the instant messaging app.",
    },
    guidedTourVignette2Text1: {
        fr:
            "Chaque service est présenté sur une carte, où se trouvent un bouton pour y accéder " +
            "directement et un autre pour accéder à ses détails.",
        en:
            "Each service is presented on a card, on which you can find a direct access button " +
            "and an other one for detailed information.",
    },
    guidedTourVignette2Text2: {
        fr: "Un cône de signalisation vous barre l'accès lorsque le service est indisponible.",
        en: "A traffic cone indicates that the service is currently unavailable.",
    },
    guidedTourVignette3Text1: {
        fr:
            "Des onglets permettent de parcourir les services selon leur niveau de 	maturité. " +
            "L'onglet alpha regroupe des applications peu mûres, beta des applications intermédiaires " +
            "et stable, comme le nom l'indique, des applications couramment utilisées, mises à jour, " +
            "et sans dysfonctionnement spécifique.",
        en:
            "Services are grouped by maturity separated over several tabs. " +
            "The Alpha tab groups unmature services, Beta intermediate ones, and Stable indicates that " +
            "services are widely used, updated and with no specific malfunction.",
    },
    guidedTourVignette3Text2: {
        fr: "Poursuivons la visite avec le catalogue du libre-service.",
        en: "We continue the visit with the self service catalog.",
    },
    guidedTourSelfServiceCatalogTitle: {
        fr: "Catalogue du libre service",
        en: "Self service catalog",
    },
    guidedTourVignette4Text1: {
        fr:
            "Le catalogue vous permet de parcourir l'ensemble de l'offre disponible et de démarrer " +
            "les applications en quelques clics seulement.",
        en:
            "The self service catalog allows to browse the set of available self services and to start " +
            "running them within a few clicks only.",
    },
    guidedTourVignette4Text2: {
        fr:
            "Les services personnels de la plateforme sont éphémères. Les données que " +
            "vous y chargez disparaissent lorsque le service s'interrompt, ce  " +
            "qui arrive chaque week-end, lors d'opérations de maintenance et également " +
            "lorsque vous les arrêtez volontairement.",
        en:
            "Self services offered by the datalab are transient. Data you load disappears " +
            "when the service stops. It happens every week-end, on maintenance operation and " +
            "also when you stop them purposely.",
    },
    guidedTourVignette5Text1: {
        fr:
            "Une carte fournit une brève description de chacun des services à votre " +
            "disposition. Un bouton bleu permet d'accéder à la page de création.",
        en:
            "A card shortly describes each self service. A blue button allows to access to the " +
            "creation page.",
    },
    guidedTourVignette5Text2: {
        fr: "Créons ensemble votre premier service !",
        en: "Let's create your first self service!",
    },
    guidedTourSelfServiceCreationTitle: {
        fr: "Creation d'un service personnel",
        en: "Self service creation",
    },
    guidedTourVignette6Text1: {
        fr:
            "Sur cette page, vous pouvez configurer votre service en parcourant " +
            "les différents onglets d'options. Le strict nécessaire est " +
            "rempli par défaut. Une fois terminé, vous pouvez valider la demande " +
            "en cliquant sur le bouton créer.",
        en:
            "On this page you can configure your service by browsing the several tabs. " +
            "Bare minimum is filled by default. Once done, you can confirm the demand " +
            "by clicking on the creation button.",
    },
    guidedTourVignette6Text2: {
        fr: "créer votre service",
        en: "create your service",
    },
    guidedTourVignette7Text1: {
        fr:
            "Votre service est en cours de création. Vous pouvez suivre " +
            "l'avancement de l'opération sur votre laboratoire personnel.",
        en: "Your self service is in progress. You can see the status on you personal lab. ",
    },
    guidedTourMyLabTitle: {
        fr: "Mon labo",
        en: "My lab",
    },
    guidedTourVignette8Text1: {
        fr:
            "Votre labo contient tous les services que vous avez créés sous forme " +
            "de cartes. Certains sont regroupés lorsqu'ils sont instanciés " +
            "par lot. Chacune des cartes vous fournit des indicateurs synthétiques " +
            "sur vos consommations.",
        en:
            "Your lab contains all the services you have created presented on cards. " +
            "Some are grouped when they are batch instantiated. Cards show synthetic information " +
            "on your personal consumption.",
    },
    guidedTourVignette8Text2: {
        fr:
            "Si votre service n'est pas encore disponible, des roues crantées " +
            "vous en bloqueront l'accès temporairement.",
        en:
            "If the creation of your service is still in progress, cog wheels will temporarily " +
            "block your access.",
    },
    guidedTourVignette9Text1: {
        fr:
            "Une fois actif, vous pouvez accéder au service dès lors que celui-ci " +
            "dispose d'une adresse web. Ce n'est pas le cas de tous les services." +
            "PostgreSQL ne possède pas d'interface web. Il vous " +
            "faut dans ce cas utiliser un second service, pgAdmin pour manipuler " +
            "votre base de données.",
        en:
            "Once active, you can directly access to it, as long as it owns a web address. " +
            "Most services do, but few does not, such as PostgreSQL. In that case, you " +
            "may use an other service, for example pgAdmin, to manipulate your database.",
    },
    guidedTourVignette9Text2: {
        fr: "ouvre l'interface web de votre service.",
        en: "opens the service web interface",
    },
    guidedTourVignette10Text1: {
        fr:
            "Au-dessus des cartes de services, une barre d'outils vous permet " +
            "d'arrêter l'ensemble des applications actives ou de rafraîchir la liste.",
        en:
            "On top of each card, a tool bar allows you to stop all active services or " +
            "to refresh them.",
    },
    guidedTourVignette11Text1: {
        fr:
            "Vous pouvez maintenant accéder à la page de paramétrage de votre " +
            "application en cliquant sur le bouton détail de la carte.",
        en:
            "You can now access to the parameter page of your service by clicking " +
            "on the detail button.",
    },
    guidedTourMySelfServiceTitle: {
        fr: "Mon service",
        en: "My lab",
    },
    guidedTourVignette12Text1: {
        fr:
            "Sur la page de votre service, vous trouverez l'ensemble des " +
            "informations propres à celui-ci. Vous pouvez ici modifier un certain " +
            "nombre d'éléments comme les ressources mises en oeuvre par	l'application.",
        en:
            "On the service page, you can find all specific information. You can modify " +
            "some of them, such as the resources accessed by your service.",
    },
    guidedTourVignette12Text2: {
        fr:
            "Des boutons d'action vous permettent de contrôler l'état de votre service " +
            "et de le supprimer.",
        en: "Action buttons allow you to control the state of your service and to shut it down.",
    },
    guidedTourClosing: {
        fr: "Merci de votre visite !",
        en: "Thanks for visiting!",
    },
};

export default dictionary;
