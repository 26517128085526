import { useState, useRef, memo } from "react";
import type { KcProps } from "keycloakify/lib/components/KcProps";
import { getCurrentKcLanguageTag, getMsg } from "keycloakify";
import { useConstCallback } from "powerhooks/useConstCallback";
import Link from "@mui/material/Link";
import { makeStyles, Text } from "ui/theme";
import { Button, Direction } from "@mui/material";
import { getConfig } from "config/batch";
import { Input } from "@avidkit/input";
import { detectDirection } from "ui/tools/detectDirection";
import { Alert } from "@mui/material";
import { getReverseMessage } from "ui/extendKcMessages/getReverseMessage";
import { KcContext } from "../kcContext";
import { Template } from "../Template/Template";

type KcContext_ResetPassword = Extract<KcContext, { pageId: "login-reset-password.ftl" }>;

export const ResetPassword = memo(
    ({ kcContext, ...props }: { kcContext: KcContext_ResetPassword } & KcProps) => {
        const { msg, msgStr, advancedMsgStr } = getMsg(kcContext);

        const [username, setUsername] = useState("");

        const { realm, url, auth } = kcContext;

        const submitButtonRef = useRef<HTMLButtonElement>(null);

        const onGoBack = useConstCallback(() => global.history.back());

        const direction = detectDirection(kcContext);

        const { classes } = useStyles({
            direction,
            providersLength: 0,
            alertType: kcContext.message?.type,
        });

        const currentLanguageTag = getCurrentKcLanguageTag(kcContext);

        let resetWith: string = msgStr("username");
        if (realm.loginWithEmailAllowed) {
            if (realm.registrationEmailAsUsername) {
                resetWith = msgStr("email");
            } else {
                resetWith = msgStr("usernameOrEmail");
            }
        }

        const { pages, logo, messages } = getConfig(kcContext);
        const pageConfig = pages.resetPassword;

        return (
            <Template
                {...{ kcContext, ...props, isLogin: true }}
                leftToRightRatio={pageConfig.leftToRightRatio}
                rightSectionHeader={
                    messages[pageConfig.rightSectionHeader][currentLanguageTag]
                }
                rightSectionText={
                    messages[pageConfig.rightSectionText][currentLanguageTag]
                }
                rightSectionImage={pageConfig.rightSectionImage}
                logoImage={logo}
                doFetchDefaultThemeResources={false}
                onClickCross={onGoBack}
                displayWide={true}
                headerNode={""}
                formNode={
                    <div className={classes.root}>
                        <div>
                            <h3 className={classes.headerNode}>
                                {msgStr("forgotPasswordDeclarative" as any)}
                            </h3>
                        </div>
                        {
                            <div className={classes.linkToLoginWrapper}>
                                <Text
                                    typo="body 2"
                                    color="disabled"
                                    className={classes.resetPasswordDescriptionContent}
                                >
                                    {msgStr("alreadyMember" as any)}
                                </Text>
                                <Link
                                    href={url.loginUrl}
                                    className={classes.registerLink}
                                    underline="hover"
                                >
                                    {msgStr("doLogIn")}
                                </Link>
                            </div>
                        }
                        <div className={classes.bannerPlaceHolder}>
                            {/* App-initiated actions should not see warning messages about the need to complete the action during login.*/}
                            {kcContext.message !== undefined &&
                                (kcContext.message.type !== "warning" ||
                                    !kcContext.isAppInitiatedAction) && (
                                    <Alert
                                        className={classes.alert}
                                        severity={kcContext.message.type}
                                    >
                                        <Text
                                            typo="label 2"
                                            className={classes.bannerText}
                                        >
                                            {getReverseMessage(
                                                kcContext,
                                                kcContext.message.summary,
                                            )}
                                        </Text>
                                    </Alert>
                                )}
                        </div>
                        <div className={classes.resetPasswordDescriptionContainer}>
                            <Text
                                typo="body 1"
                                color="primary"
                                className={classes.resetPasswordDescriptionHeader}
                            >
                                {advancedMsgStr("promptHeader", resetWith.toLowerCase())}
                            </Text>
                            <Text
                                typo="body 2"
                                color="disabled"
                                className={classes.resetPasswordDescriptionContent}
                            >
                                {advancedMsgStr(
                                    "resetPasswordDescriptionContent",
                                    resetWith.toLowerCase(),
                                )}
                            </Text>
                        </div>
                        <div>
                            {
                                <form
                                    action={url.loginAction}
                                    method="post"
                                    className={classes.form}
                                >
                                    <Input
                                        autoFocus
                                        tabIndex={1}
                                        id="username"
                                        name="username"
                                        inputProps_aria-label={resetWith}
                                        label={resetWith}
                                        autoComplete="off"
                                        value={username}
                                        onChange={setUsername}
                                        defaultValue={
                                            auth !== undefined && auth.showUsername
                                                ? auth.attemptedUsername
                                                : undefined
                                        }
                                    />
                                    <div className={classes.buttonsWrapper}>
                                        <Button
                                            ref={submitButtonRef}
                                            tabIndex={3}
                                            className={classes.buttonSubmit}
                                            name="reset"
                                            type="submit"
                                        >
                                            {msgStr("sendEmail" as any)}
                                        </Button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                }
                infoNode={msg("emailInstruction")}
            />
        );
    },
);

const useStyles = makeStyles<{
    direction: Direction;
    providersLength: number;
    alertType?: "success" | "warning" | "error" | "info";
}>({
    "name": { ResetPassword },
})((theme, { direction, alertType }) => {
    const { blueInfo, greenSuccess, orangeWarning, redError } = theme.colors.palette;
    let alertColor: { main: string; light: string };
    switch (alertType) {
        case "info":
            alertColor = blueInfo;
            break;
        case "success":
            alertColor = greenSuccess;
            break;
        case "warning":
            alertColor = orangeWarning;
            break;
        case "error":
            alertColor = redError;
            break;
        default:
            alertColor = blueInfo;
    }
    return {
        "root": {
            "direction": direction,
            "width": "80%",
            "margin": "auto",
            "& .MuiTextField-root": {
                "width": "100%",
                "marginTop": theme.spacing(5),
            },
        },
        "headerNode": {
            "margin": "0.2em 0",
            "padding": "0",
        },
        "buttonsWrapper": {
            "marginTop": "-10px",
            "display": "flex",
            "justifyContent": "flex-end",
        },
        "buttonSubmit": {
            "fontSize": "18px",
            "textTransform": "none",
            "marginLeft": theme.spacing(2),
            "backgroundColor": theme.colors.palette.limeGreen.light,
            "opacity": "0.5",
            "borderRadius": "8px",
            "color": "white",
            "width": "50%",
            "height": "57px",
            "margin": 0,
            "padding": 0,
            "&:hover": {
                "backgroundColor": theme.colors.palette.limeGreen.light,
                "color": "white",
            },
            "&:disabled": {
                "backgroundColor": theme.colors.palette.limeGreen.light,
                "color": "white",
                "opacity": "0.2",
            },
        },

        "linkToLoginWrapper": {
            "textAlign": "center",
            "display": "flex",
            "& > *": {
                "display": "inline-block",
            },
        },
        "bannerPlaceHolder": {
            "display": "flex",
            "alignItems": "center",
            "width": "100%",
            "height": "36px",
        },
        "bannerText": {
            "color": alertColor.main,
        },
        "resetPasswordDescriptionContent": {
            "fontSize": "14px",
            "marginTop": "2px",
            "lineHeight": "1.2",
            "color": "#7b818b",
        },
        "registerLink": {
            "paddingLeft": theme.spacing(2),
            "paddingRight": theme.spacing(2),
            "color": theme.colors.palette.limeGreen.main,
        },
        "resetPasswordDescriptionContainer": {
            "margin-top": "20px",
            "margin-bottom": "50px",
        },
        "resetPasswordDescriptionHeader": {
            "margin": "0",
        },
        "form": {
            "display": "grid",
            "gridTemplate": "auto / auto",
            "gridRowGap": "32px",
        },
        "alert": {
            "alignItems": "center",
            "backgroundColor": alertColor.light,
            "width": "100%",
            "direction": direction,
            "padding": "0 6px",
            "height": "20px",
        },
    };
});
