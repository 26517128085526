import { Annotation } from "./Type";

export const getAnnotations = (
    annotations: Record<string, string>,
): Annotation | null => {
    if (!annotations.type) {
        return null;
    }
    if (annotations.type === "select") {
        if (!annotations.values) {
            return null;
        }
        return {
            type: "select",
            values: annotations.values.split(","),
        };
    }
    return null;
};
