import { useState, useRef, memo } from "react";
import type { KcProps } from "keycloakify/lib/components/KcProps";
import { getCurrentKcLanguageTag, getMsg } from "keycloakify";
import { useConstCallback } from "powerhooks/useConstCallback";
import { makeStyles, Text } from "ui/theme";
import { Button, Direction } from "@mui/material";
import { getConfig } from "config/batch";
import { detectDirection } from "ui/tools/detectDirection";
import { KcContext } from "../kcContext";
import { Template } from "../Template/Template";
import Password from "../Password";

type KcContext_UpdatePassword = Extract<
    KcContext,
    { pageId: "login-update-password.ftl" }
>;

export const UpdatePassword = memo(
    ({ kcContext, ...props }: { kcContext: KcContext_UpdatePassword } & KcProps) => {
        const { msg, msgStr } = getMsg(kcContext);

        const [username] = useState("");

        const { url } = kcContext;

        const submitButtonRef = useRef<HTMLButtonElement>(null);

        const onGoBack = useConstCallback(() => global.history.back());

        const direction = detectDirection(kcContext);

        const { classes } = useStyles({
            direction,
            providersLength: 0,
            alertType: kcContext.message?.type,
        });

        const currentLanguageTag = getCurrentKcLanguageTag(kcContext);

        const { pages, logo, messages } = getConfig(kcContext);
        const pageConfig = pages.updatePassword;

        const [password, setPassword] = useState("");
        const [confirmPassword, setConfirmPassword] = useState("");

        const [error, setError] = useState({
            password: "",
            confirmPassword: "",
        });

        const onInputChange = (value: string, name: "password" | "confirmPassword") => {
            if (name === "password") {
                setPassword(value);
            } else {
                setConfirmPassword(value);
            }
            validateInput(value, name);
        };

        const validateInput = (value: string, name: "password" | "confirmPassword") => {
            setError(prev => {
                const stateObj = { ...prev, [name]: "" };
                switch (name) {
                    case "password":
                        if (!value || value === "") {
                            stateObj[name] = msgStr("enterPassword" as any);
                        } else if (confirmPassword && value !== confirmPassword) {
                            stateObj["confirmPassword"] = msgStr(
                                "invalidPasswordConfirmMessage",
                            );
                        } else {
                            stateObj["confirmPassword"] = confirmPassword
                                ? ""
                                : error.confirmPassword;
                        }
                        break;

                    case "confirmPassword":
                        if (!value) {
                            stateObj[name] = msgStr("enterConfirmPassword" as any);
                        } else if (password && value !== password) {
                            stateObj[name] = msgStr("invalidPasswordConfirmMessage");
                        }
                        break;

                    default:
                        break;
                }

                return stateObj;
            });
        };
        return (
            <Template
                {...{ kcContext, ...props, isLogin: true }}
                leftToRightRatio={pageConfig.leftToRightRatio}
                rightSectionHeader={
                    messages[pageConfig.rightSectionHeader][currentLanguageTag]
                }
                rightSectionText={
                    messages[pageConfig.rightSectionText][currentLanguageTag]
                }
                rightSectionImage={pageConfig.rightSectionImage}
                logoImage={logo}
                doFetchDefaultThemeResources={false}
                onClickCross={onGoBack}
                displayWide={true}
                headerNode={""}
                formNode={
                    <div className={classes.root}>
                        <div>
                            <h3 className={classes.headerNode}>
                                {msgStr("resetPassword" as any)}
                            </h3>
                        </div>
                        {
                            <div className={classes.linkToLoginWrapper}>
                                <Text
                                    typo="body 2"
                                    color="disabled"
                                    className={classes.resetPasswordDescriptionContent}
                                >
                                    {msgStr("changePasswordText" as any)}
                                </Text>
                            </div>
                        }
                        <div className={classes.bannerPlaceHolder}></div>
                        <div>
                            {
                                <form
                                    action={url.loginAction}
                                    method="post"
                                    className={classes.form}
                                >
                                    <input
                                        type="text"
                                        id="username"
                                        name="username"
                                        value={username}
                                        readOnly={true}
                                        autoComplete="username"
                                        style={{ display: "none" }}
                                    />
                                    <Password
                                        dir={direction}
                                        showStrength={true}
                                        id={"password"}
                                        name={"password-new"}
                                        inputProps_aria-label={"password"}
                                        label={msgStr("passwordNew")}
                                        inputProps_aria-invalid={false}
                                        value={password}
                                        onChange={e => onInputChange(e, "password")}
                                        onBlur={e =>
                                            validateInput(e.target.value, "password")
                                        }
                                    />
                                    {error.password && (
                                        <span className={classes.inputError}>
                                            {error.password}
                                        </span>
                                    )}

                                    <Password
                                        dir={direction}
                                        id={"confirmPassword"}
                                        name={"password-confirm"}
                                        inputProps_aria-label={"confirmPassword"}
                                        label={msgStr("passwordNewConfirm")}
                                        inputProps_aria-invalid={false}
                                        value={confirmPassword}
                                        onChange={e =>
                                            onInputChange(e, "confirmPassword")
                                        }
                                        onBlur={e =>
                                            validateInput(
                                                e.target.value,
                                                "confirmPassword",
                                            )
                                        }
                                    />
                                    {error.confirmPassword && (
                                        <span className={classes.inputError}>
                                            {error.confirmPassword}
                                        </span>
                                    )}

                                    <div className={classes.buttonsWrapper}>
                                        <Button
                                            ref={submitButtonRef}
                                            tabIndex={3}
                                            className={classes.buttonSubmit}
                                            name="reset"
                                            type="submit"
                                            disabled={
                                                !(
                                                    confirmPassword !== "" &&
                                                    password !== "" &&
                                                    error.confirmPassword === "" &&
                                                    error.password === ""
                                                )
                                            }
                                        >
                                            {msgStr("doSubmit")}
                                        </Button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                }
                infoNode={msg("emailInstruction")}
            />
        );
    },
);

const useStyles = makeStyles<{
    direction: Direction;
    providersLength: number;
    alertType?: "success" | "warning" | "error" | "info";
}>({
    "name": { UpdatePassword },
})((theme, { direction }) => {
    return {
        "root": {
            "direction": direction,
            "width": "100%",
            "margin": "0 auto",
            "& .MuiTextField-root": {
                "width": "100%",
                "marginTop": theme.spacing(5),
            },
        },
        "headerNode": {
            "margin": "0.2em 0",
            "padding": "0",
        },
        "buttonsWrapper": {
            "marginTop": "16px",
            "display": "flex",
            "justifyContent": "flex-end",
        },
        "buttonSubmit": {
            "fontSize": "18px",
            "textTransform": "none",
            "marginLeft": theme.spacing(2),
            "backgroundColor": theme.colors.palette.limeGreen.light,
            "opacity": "0.5",
            "borderRadius": "8px",
            "color": "white",
            "width": "50%",
            "height": "57px",
            "margin": 0,
            "padding": 0,
            "&:hover": {
                "backgroundColor": theme.colors.palette.limeGreen.light,
                "color": "white",
            },
            "&:disabled": {
                "backgroundColor": theme.colors.palette.limeGreen.light,
                "color": "white",
                "opacity": "0.2",
            },
        },

        "linkToLoginWrapper": {
            "textAlign": "center",
            "display": "flex",
            "& > *": {
                "display": "inline-block",
            },
        },
        "bannerPlaceHolder": {
            "display": "flex",
            "alignItems": "center",
            "width": "100%",
            "height": "36px",
        },
        "resetPasswordDescriptionContent": {
            "fontSize": "14px",
            "marginTop": "2px",
            "lineHeight": "1.2",
            "color": "#7b818b",
        },
        "form": {
            "display": "grid",
            "gridTemplate": "auto / auto",
            "gridRowGap": "16px",
        },
        "inputError": {
            "color": "red",
            "fontSize": "14px",
        },
    };
});
