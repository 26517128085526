import { useMemo, memo, useEffect, Fragment, useState } from "react";
import { Template } from "./Template/Template";
import { getCurrentKcLanguageTag, KcProps } from "keycloakify";
import type { KcContextBase } from "keycloakify";
import { getMsg } from "keycloakify";
import { useCallbackFactory } from "powerhooks/useCallbackFactory";
import { useFormValidationSlice } from "keycloakify";
import { makeStyles, Text } from "ui/theme";
import Link from "@mui/material/Link";
import { useConstCallback } from "powerhooks/useConstCallback";
import { Button, Checkbox, Direction, Tooltip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getConfig } from "config/batch";
import { Input, InputProps } from "@avidkit/input";
import { detectDirection } from "ui/tools/detectDirection";
import { getInputPlaceHolder } from "ui/tools/getInputPlaceholder";
import { Param0 } from "tsafe";
import { getReverseMessage } from "ui/extendKcMessages/getReverseMessage";
import { getAnnotations } from "ui/annotations/getAnnotations";
import { SelectProps, Select } from "./Selectbox";
import { Password } from "./Password";

export const RegisterUserProfile = memo(
    ({
        kcContext,
        ...props_
    }: { kcContext: KcContextBase.RegisterUserProfile } & KcProps) => {
        const { url, messagesPerField, recaptchaRequired, recaptchaSiteKey } = kcContext;

        const { msg, msgStr, advancedMsg } = getMsg(kcContext);

        const direction = detectDirection(kcContext);

        const isBigScreen = useMediaQuery("(min-width: 1000px)");

        const { classes, cx, css } = useStyles({ direction, isBigScreen });

        const props = useMemo(
            () => ({
                ...props_,
                "kcFormGroupClass": cx(
                    props_.kcFormGroupClass,
                    css({ "marginBottom": 20 }),
                ),
            }),
            [cx, css],
        );

        const onGoBack = useConstCallback(() => global.history.back());

        const passwordValidators = useMemo(() => ({}), []);

        const [acceptTerms, setAcceptTerms] = useState(false);

        const {
            formValidationState: { fieldStateByAttributeName, isFormSubmittable },
            formValidationReducer,
            attributesWithPassword: unorderedAttributesWithPassword,
        } = useFormValidationSlice({
            kcContext,
            passwordValidators,
        });

        const attributesWithPassword = useMemo(
            () =>
                unorderedAttributesWithPassword.sort(
                    (a, b) =>
                        getHardCodedFieldWeight(b.name) - getHardCodedFieldWeight(a.name),
                ),
            [unorderedAttributesWithPassword],
        );

        const onTextChangeFactory = useCallbackFactory(
            ([name]: [string], [value]: [Param0<InputProps["onChange"]>]) =>
                formValidationReducer({
                    "action": "update value",
                    name,
                    "newValue": value,
                }),
        );

        const onSelectChangeFactory = useCallbackFactory(
            ([name]: [string], [value]: [Param0<SelectProps["onSelect"]>]) =>
                formValidationReducer({
                    "action": "update value",
                    name,
                    "newValue": value.label,
                }),
        );

        const onBlurFactory = useCallbackFactory(([name]: [string]) =>
            formValidationReducer({
                "action": "focus lost",
                name,
            }),
        );

        useEffect(() => {
            attributesWithPassword
                .filter(
                    ({ name }) =>
                        !["username", "email", "password", "password-confirm"].includes(
                            name,
                        ),
                )
                .map(({ name }) =>
                    formValidationReducer({
                        "action": "focus lost",
                        name,
                    }),
                );
        }, []);

        const areAllFieldsRequired = useMemo(
            () => attributesWithPassword.every(({ required }) => required),
            [attributesWithPassword],
        );

        let currentGroup = "";

        const getIncrementedTabIndex = (() => {
            let counter = 1;
            return () => counter++;
        })();

        const currentLanguageTag = getCurrentKcLanguageTag(kcContext) as string;

        const { pages, logo, termsLink, messages, extraFields } = getConfig(kcContext);
        const pageConfig = pages.register;

        return (
            <Template
                {...{ kcContext, ...props, isLogin: false }}
                leftToRightRatio={pageConfig.leftToRightRatio}
                rightSectionHeader={
                    messages[pageConfig.rightSectionHeader][currentLanguageTag]
                }
                rightSectionText={
                    messages[pageConfig.rightSectionText][currentLanguageTag]
                }
                rightSectionImage={pageConfig.rightSectionImage}
                logoImage={logo}
                displayMessage={messagesPerField.exists("global")}
                displayRequiredFields={false}
                doFetchDefaultThemeResources={false}
                onClickCross={onGoBack}
                headerNode={msg("registerTitle")}
                formNode={
                    <>
                        {
                            <div className={classes.linkToLoginWrapper}>
                                <Text
                                    typo="body 2"
                                    color="disabled"
                                    className={classes.loginText}
                                >
                                    {msg("loginAccountTitle")!}
                                </Text>
                                <Link
                                    href={url.loginUrl}
                                    className={classes.link}
                                    underline="hover"
                                >
                                    {msgStr("doLogIn")}
                                </Link>
                            </div>
                        }
                        <form
                            className={classes.root}
                            action={url.registrationAction}
                            method="post"
                        >
                            <>
                                {attributesWithPassword.map((attribute, i) => {
                                    const {
                                        group = "",
                                        groupDisplayHeader = "",
                                        groupDisplayDescription = "",
                                    } = attribute;

                                    const { displayableErrors } =
                                        fieldStateByAttributeName[attribute.name];

                                    const formGroupClassName = cx(
                                        props.kcFormGroupClass,
                                        displayableErrors.length !== 0 &&
                                            props.kcFormGroupErrorClass,
                                    );

                                    let input;
                                    const annotations = getAnnotations(
                                        attribute.annotations,
                                    );

                                    const label = `${
                                        advancedMsg(
                                            extraFields[attribute.name]?.[
                                                currentLanguageTag
                                            ] ?? attribute.displayName!,
                                        ).props.children
                                    } ${
                                        !areAllFieldsRequired && attribute.required
                                            ? "*"
                                            : ""
                                    }`;

                                    if (annotations) {
                                        switch (annotations.type) {
                                            case "select":
                                                const selectedValue =
                                                    fieldStateByAttributeName[
                                                        attribute.name
                                                    ].value;
                                                input = (
                                                    <>
                                                        <Select
                                                            items={annotations.values.map(
                                                                value => ({
                                                                    id: value,
                                                                    label: value,
                                                                }),
                                                            )}
                                                            placeHolder={
                                                                attribute.required
                                                                    ? msgStr(
                                                                          "selectRequired" as any,
                                                                      )
                                                                    : ""
                                                            }
                                                            selectedItems={
                                                                selectedValue
                                                                    ? [
                                                                          {
                                                                              id: selectedValue,
                                                                              label: selectedValue,
                                                                          },
                                                                      ]
                                                                    : []
                                                            }
                                                            menuSize={"Big"}
                                                            onSelect={onSelectChangeFactory(
                                                                attribute.name,
                                                            )}
                                                            label={label}
                                                        />
                                                        <input
                                                            id={attribute.name}
                                                            name={attribute.name}
                                                            hidden={true}
                                                            value={
                                                                fieldStateByAttributeName[
                                                                    attribute.name
                                                                ].value
                                                            }
                                                        />
                                                    </>
                                                );
                                                break;
                                        }
                                    } else {
                                        switch (attribute.name) {
                                            case "password-confirm":
                                            case "password":
                                                input = (
                                                    <Password
                                                        dir={direction}
                                                        showStrength={
                                                            attribute.name === "password"
                                                        }
                                                        placeholder={getInputPlaceHolder(
                                                            attribute.name,
                                                        )}
                                                        id={attribute.name}
                                                        name={attribute.name}
                                                        aria-invalid={
                                                            displayableErrors.length !== 0
                                                        }
                                                        disabled={attribute.readOnly}
                                                        autoComplete={
                                                            attribute.autocomplete
                                                        }
                                                        onBlur={onBlurFactory(
                                                            attribute.name,
                                                        )}
                                                        inputProps_aria-label={
                                                            attribute.name
                                                        }
                                                        label={label}
                                                        inputProps_aria-invalid={
                                                            fieldStateByAttributeName[
                                                                attribute.name
                                                            ].displayableErrors.length !==
                                                            0
                                                        }
                                                        value={
                                                            fieldStateByAttributeName[
                                                                attribute.name
                                                            ].value
                                                        }
                                                        onChange={onTextChangeFactory(
                                                            attribute.name,
                                                        )}
                                                    />
                                                );
                                                break;
                                            default:
                                                input = (
                                                    <Input
                                                        placeholder={getInputPlaceHolder(
                                                            attribute.name,
                                                        )}
                                                        id={attribute.name}
                                                        name={attribute.name}
                                                        aria-invalid={
                                                            displayableErrors.length !== 0
                                                        }
                                                        disabled={attribute.readOnly}
                                                        autoComplete={
                                                            attribute.autocomplete
                                                        }
                                                        onBlur={onBlurFactory(
                                                            attribute.name,
                                                        )}
                                                        inputProps_aria-label={
                                                            attribute.name
                                                        }
                                                        label={label}
                                                        inputProps_aria-invalid={
                                                            fieldStateByAttributeName[
                                                                attribute.name
                                                            ].displayableErrors.length !==
                                                            0
                                                        }
                                                        value={
                                                            fieldStateByAttributeName[
                                                                attribute.name
                                                            ].value
                                                        }
                                                        onChange={onTextChangeFactory(
                                                            attribute.name,
                                                        )}
                                                    />
                                                );
                                        }
                                    }
                                    return (
                                        <div>
                                            <Fragment key={i}>
                                                {group !== currentGroup &&
                                                    (currentGroup = group) !== "" && (
                                                        <div
                                                            className={formGroupClassName}
                                                        >
                                                            <div
                                                                className={cx(
                                                                    props.kcContentWrapperClass,
                                                                )}
                                                            >
                                                                <label
                                                                    id={`header-${group}`}
                                                                    className={cx(
                                                                        props.kcFormGroupHeader,
                                                                    )}
                                                                >
                                                                    {advancedMsg(
                                                                        groupDisplayHeader,
                                                                    ) || currentGroup}
                                                                </label>
                                                            </div>
                                                            {groupDisplayDescription !==
                                                                "" && (
                                                                <div
                                                                    className={cx(
                                                                        props.kcLabelWrapperClass,
                                                                    )}
                                                                >
                                                                    <label
                                                                        id={`description-${group}`}
                                                                        className={`${cx(
                                                                            props.kcLabelClass,
                                                                        )}`}
                                                                    >
                                                                        {advancedMsg(
                                                                            groupDisplayDescription,
                                                                        )}
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                {input}
                                            </Fragment>
                                            {(() => {
                                                const displayableErrors =
                                                    fieldStateByAttributeName[
                                                        attribute.name
                                                    ].displayableErrors;

                                                if (displayableErrors.length !== 0) {
                                                    const batchErrorMessage =
                                                        displayableErrors
                                                            .map(
                                                                displayableError =>
                                                                    displayableError.errorMessageStr,
                                                            )
                                                            .join("<br/>");

                                                    return (
                                                        <span
                                                            key={i}
                                                            className={classes.inputError}
                                                        >
                                                            {getReverseMessage(
                                                                kcContext,
                                                                batchErrorMessage,
                                                            )}
                                                        </span>
                                                    );
                                                }

                                                return undefined;
                                            })()}
                                        </div>
                                    );
                                })}
                            </>
                            {recaptchaRequired && (
                                <div className="form-group">
                                    <div className={cx(props.kcInputWrapperClass)}>
                                        <div
                                            className="g-recaptcha"
                                            data-size="compact"
                                            data-sitekey={recaptchaSiteKey}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={classes.buttonsWrapper}>
                                <div className={classes.termsWrapper}>
                                    <Checkbox
                                        value={acceptTerms}
                                        onChange={() => setAcceptTerms(!acceptTerms)}
                                        className={classes.termsCheckbox}
                                    />
                                    <Link
                                        href={termsLink}
                                        className={classes.link}
                                        underline="hover"
                                        target={"blank"}
                                    >
                                        {msgStr("termsTitle")}
                                    </Link>
                                </div>
                                <Tooltip
                                    title={(() => {
                                        const messageArray: string[] = [];
                                        if (!isFormSubmittable) {
                                            messageArray.push(
                                                msgStr("properFormFill" as any),
                                            );
                                        }
                                        if (!acceptTerms) {
                                            messageArray.push(msgStr("termsPlainText"));
                                        }
                                        return messageArray.join(", ");
                                    })()}
                                    disableHoverListener={
                                        acceptTerms && isFormSubmittable
                                    }
                                >
                                    <div className={classes.buttonSubmitWrapper}>
                                        <Button
                                            className={classes.buttonSubmit}
                                            type="submit"
                                            tabIndex={getIncrementedTabIndex()}
                                            disabled={!acceptTerms || !isFormSubmittable}
                                        >
                                            {msgStr("doRegister")}
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                        </form>
                    </>
                }
            />
        );
    },
);

const { getHardCodedFieldWeight } = (() => {
    const orderedFields = [
        "firstName",
        "lastName",
        "email",
        "username",
        "password",
        "password-confirm",
    ].map(fieldName => fieldName.toLowerCase());

    function getHardCodedFieldWeight(fieldName: string) {
        for (let i = 0; i < orderedFields.length; i++) {
            if (fieldName.toLowerCase().includes(orderedFields[i])) {
                return orderedFields.length - i;
            }
        }

        return 0;
    }

    return { getHardCodedFieldWeight };
})();

const useStyles = makeStyles<{ direction: Direction; isBigScreen: boolean }>({
    "name": { RegisterUserProfile },
})((theme, { direction, isBigScreen }) => ({
    "root": {
        "direction": direction,
        "display": "grid",
        "gridTemplate": isBigScreen ? "auto / 285px 285px" : "auto / auto",
        "gridColumnGap": "23px",
        "gridRowGap": "32px",
    },
    "buttonsWrapper": {
        "gridColumn": isBigScreen ? "1 / span 2" : "1 / span 1",
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "flexDirection": isBigScreen ? "row" : "column",
        "marginBottom": "20px",
    },
    "termsWrapper": {
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "flex-start",
        "width": isBigScreen ? undefined : "100%",
        "paddingBottom": isBigScreen ? undefined : "23px",
    },
    "buttonSubmit": {
        "fontSize": "18px",
        "textTransform": "none",
        "marginLeft": theme.spacing(2),
        "backgroundColor": theme.colors.palette.limeGreen.light,
        "opacity": "0.5",
        "borderRadius": isBigScreen ? "12px" : "22px",
        "color": "white",
        "height": "57px",
        "width": "100%",
        "margin": 0,
        "padding": 0,
        "marginBottom": isBigScreen ? undefined : "32px",
        "&:hover": {
            "backgroundColor": theme.colors.palette.limeGreen.light,
            "color": "white",
        },
        "&:disabled": {
            "backgroundColor": theme.colors.palette.limeGreen.light,
            "color": "white",
            "opacity": "0.2",
        },
    },
    "buttonSubmitWrapper": {
        "width": isBigScreen ? "205px" : "100%",
        "height": "57px",
    },
    "termsCheckbox": {
        "padding": 0,
    },
    "linkToLoginWrapper": {
        "direction": direction,
        "marginBottom": "36px",
        "textAlign": "center",
        "display": "flex",
        "& > *": {
            "display": "inline-block",
        },
    },
    "loginText": {
        "fontSize": "14px",
        "marginTop": "2px",
        "color": "#7b818b",
    },
    "link": {
        "paddingLeft": theme.spacing(2),
        "paddingRight": theme.spacing(2),
        "color": theme.colors.palette.limeGreen.main,
    },
    "inputError": {
        "color": "red",
        "fontSize": "14px",
    },
}));
