/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useMemo, useRef } from "react";
import type { ReactNode } from "react";
import type { KcTemplateProps } from "keycloakify";
import { makeStyles, Text } from "ui/theme";
import { useDomRect } from "onyxia-ui";
import type { KcContext } from "../../kcContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useOnScreen } from "ui/tools/useOnScreen";
import { detectDirection } from "ui/tools/detectDirection";
import { Direction } from "@mui/system";
import { TemplateHead } from "./Head";
import { TemplateMain } from "./Main";
import { IconButton } from "ui/theme";

export type TemplatePageProps = {
    isLogin: boolean;
    leftToRightRatio: number;
    rightSectionHeader: string;
    rightSectionText: string;
    rightSectionImage: string;
    logoImage: string;
    className?: string;
    displayInfo?: boolean;
    displayMessage?: boolean;
    displayRequiredFields?: boolean;
    displayWide?: boolean;
    showAnotherWayIfPresent?: boolean;
    headerNode: ReactNode;
    showUsernameNode?: ReactNode;
    formNode: ReactNode;
    infoNode?: ReactNode;
    onClickCross: (() => void) | undefined;
} & { kcContext: KcContext } & KcTemplateProps;

export const TemplatePage = memo((props: TemplatePageProps) => {
    const {
        isLogin,
        className,
        displayInfo = false,
        displayMessage = true,
        displayRequiredFields = false,
        displayWide = false,
        showAnotherWayIfPresent = true,
        headerNode,
        showUsernameNode = null,
        formNode,
        infoNode = null,
        kcContext,
        leftToRightRatio,
        rightSectionHeader,
        rightSectionText,
        rightSectionImage,
        logoImage,
        onClickCross,
        ...kcProps
    } = props;

    console.log(isLogin);
    const direction = detectDirection(kcContext);

    const isBigWidth = useMediaQuery("(min-width: 1000px)");

    const {
        ref: containerRef,
        domRect: { height: containerHeight },
    } = useDomRect();
    const {
        ref: paperRef,
        domRect: { height: paperHeight },
    } = useDomRect();

    const headerRef = useRef<HTMLDivElement>(null);
    const isHeaderOnScreen = useOnScreen(headerRef);

    const { classes, cx } = useStyles({
        "isPaperBiggerThanContainer": paperHeight > containerHeight,
        "isLogin": isLogin,
        "isBigWidth": isBigWidth,
        "leftToRightRatio": leftToRightRatio,
        "isHeaderOnScreen": isHeaderOnScreen,
        "direction": direction,
        "hasCrossClick": !!onClickCross,
    });

    const momoizedClasses = useMemo(() => {
        return cx(classes.root, className);
    }, []);

    return (
        <div ref={containerRef} className={momoizedClasses}>
            <div ref={paperRef} className={classes.paper}>
                <div className={classes.bothSectionsWrapper}>
                    <div className={classes.leftSectionWrapper}>
                        <div className={classes.leftSectionHeaderWrapper}>
                            <div className={classes.crossClickWrapper}>
                                {onClickCross && (
                                    <IconButton
                                        iconClassName={classes.crossClick}
                                        iconId="close"
                                        onClick={onClickCross}
                                    />
                                )}
                            </div>
                            <div className={classes.headerOuterWrapper}>
                                <div
                                    hidden={isHeaderOnScreen}
                                    className={classes.headerInnerWrapper}
                                >
                                    <Text className={classes.root} typo="section heading">
                                        {headerNode!}
                                    </Text>
                                </div>
                            </div>
                        </div>

                        <div className={classes.withoutScrollbar}>
                            <div className={classes.contentWrapper}>
                                <TemplateHead
                                    {...{ kcContext, ...kcProps }}
                                    displayRequiredFields={displayRequiredFields}
                                    headerNode={headerNode}
                                    showUsernameNode={showUsernameNode}
                                    headerRef={headerRef}
                                    style={{ direction, display: "flex" }}
                                />
                                <TemplateMain
                                    {...{ kcContext, ...kcProps }}
                                    displayMessage={displayMessage}
                                    formNode={formNode}
                                    showAnotherWayIfPresent={showAnotherWayIfPresent}
                                    displayWide={displayWide}
                                    displayInfo={displayInfo}
                                    infoNode={infoNode}
                                    style={{
                                        direction,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {isBigWidth && (
                        <div className={classes.rightSectionWrapper}>
                            <div className={classes.rightSectionTextWrapper}>
                                <p className={classes.rightSectionHeader}>
                                    {rightSectionHeader}
                                </p>
                                <p className={classes.rightSectionText}>
                                    {rightSectionText}
                                </p>
                            </div>
                            <img
                                src={rightSectionImage}
                                className={classes.rightSectionImage}
                                alt={"logo"}
                            />
                            <div className={classes.logoWrapper}>
                                {logoImage === "realmName" ? (
                                    <Text typo="label 1">
                                        {kcContext.realm.displayName ??
                                            kcContext.realm.name}
                                    </Text>
                                ) : (
                                    <img
                                        src={logoImage}
                                        className={classes.logo}
                                        alt={"logo"}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

const useStyles = makeStyles<{
    direction: Direction;
    isLogin: boolean;
    isPaperBiggerThanContainer?: boolean;
    isBigWidth?: boolean;
    isHeaderOnScreen: boolean;
    leftToRightRatio: number;
    hasCrossClick: boolean;
}>()(
    (
        theme,
        {
            isLogin,
            isPaperBiggerThanContainer,
            isBigWidth,
            leftToRightRatio,
            isHeaderOnScreen,
            direction,
            hasCrossClick,
        },
    ) => ({
        "root": {
            "display": "flex",
            "justifyContent": "center",
            "alignItems": isPaperBiggerThanContainer ? undefined : "center",
        },
        "crossClick": {
            "fill": "#dedcdd",
            "&:hover": {
                "fill": "#f3f3f3",
            },
        },
        "paper": {
            "width": isBigWidth ? `1236px` : "100%",
            "minHeight": "625px",
            "backgroundColor": "white",
            "marginBottom": theme.spacing(4),
            "borderRadius": "1px",
            "boxShadow": "0 10px 30px 0 rgba(123, 129, 139, 0.3)",
        },
        "leftSectionHeaderWrapper": {
            "display": "flex",
            "height": "72px",
            "alignItems": "center",
            "boxShadow": isHeaderOnScreen
                ? undefined
                : "0 2px 12px 0 rgba(188, 189, 191, 0.7)",
            "zIndex": 10,
            "position": "relative",
        },
        "bothSectionsWrapper": {
            "display": "flex",
            "height": "100%",
        },
        "leftSectionWrapper": {
            "width": isBigWidth ? `${100 * (1 - 1 / (1 + leftToRightRatio))}%` : "100%",
        },
        "rightSectionWrapper": {
            "width": `${100 / (1 + leftToRightRatio)}%`,
            "backgroundColor": "rgba(249, 249, 249, 0.8)",
            "display": "flex",
            "flexDirection": "column",
            "alignItems": "center",
            "height": isLogin ? "625px" : undefined,
            "minHeight": !isLogin ? "625px" : undefined,
        },
        "rightSectionTextWrapper": {
            "overflow": "visible",
            "textOverflow": "ellipsis",
            "direction": direction,
            "paddingTop": "48px",
            "width": "70%",
            "height": "152px",
        },
        "headerOuterWrapper": {
            "display": "flex",
            "justifyContent": "space-between",
            "alignItems": "center",
            "width": "100%",
            "height": "100%",
            "paddingRight": "24px",
            "direction": direction,
        },
        "headerInnerWrapper": {
            "padding": "24px",
            "paddingRight": "24px",
            "paddingLeft": !hasCrossClick ? "24px" : 0,
        },
        "crossClickWrapper": {
            "padding": hasCrossClick ? "0 16px" : undefined,
            "width": !hasCrossClick ? "60px" : "",
        },
        "withoutScrollbar": {
            "width": "100%",
            "display": "flex",
            "justifyContent": "center",
            "height": "calc(100% - 72px)",
            "padding": "0 24px",
            "paddingLeft": isBigWidth ? "70px" : undefined,
            "msOverflowStyle": "none",
            "scrollbarWidth": "none",
            "&::-webkit-scrollbar": {
                "display": "none",
            },
        },
        "contentWrapper": {
            "minWidth": isBigWidth ? "387px" : undefined,
            "width": isBigWidth ? undefined : "100%",
        },
        "rightSectionHeader": {
            "color": "#7b818b",
            "fontWeight": "bold",
            "fontSize": "20px",
            "width": "100%",
        },
        "rightSectionText": {
            "color": "#7b818b",
            "fontSize": "16px",
            "width": "100%",
        },
        "rightSectionImage": {
            "width": "100%",
            "height": "266px",
            "padding": "0 52px",
            "flex": 1,
            "objectFit": "contain",
        },
        "logoWrapper": {
            "width": "100%",
            "display": "flex",
            "alignItems": "flex-end",
            "justifyContent": "flex-end",
            "marginTop": "88px",
            "paddingRight": "36px",
            "paddingBottom": "24px",
        },
        "logo": { "height": "36px" },
    }),
);
