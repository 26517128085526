/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo } from "react";
import type { ReactNode } from "react";
import { getMsg } from "keycloakify";
import { getCurrentKcLanguageTag } from "keycloakify";
import type { KcTemplateProps } from "keycloakify";
import { makeStyles, Text } from "ui/theme";
import type { KcContext } from "../../kcContext";
import { Select } from "../../Selectbox";
import { getLanguageFlag } from "ui/tools/getLanguageFlag";

type TemplateHeadProps = {
    displayRequiredFields: boolean;
    headerNode: ReactNode;
    showUsernameNode?: ReactNode;
    style?: React.CSSProperties;
    headerRef?: React.RefObject<HTMLDivElement>;
} & { kcContext: KcContext } & KcTemplateProps;

export const TemplateHead = memo((props: TemplateHeadProps) => {
    const {
        kcContext,
        displayRequiredFields,
        headerNode,
        showUsernameNode,
        style,
        headerRef,
        ...kcProps
    } = props;

    const { msg } = getMsg(kcContext);

    const { classes, cx } = useStyles();

    const currentLanguageTag = getCurrentKcLanguageTag(kcContext);
    const supportedLanguageTagUrl: Record<string, string> = {};
    const supportedLanguageTags: string[] = [];
    kcContext.locale?.supported.forEach(s => {
        supportedLanguageTagUrl[s.languageTag] = s.url;
        supportedLanguageTags.push(s.languageTag);
    });

    return (
        <header className={classes.header} style={style} ref={headerRef}>
            {!(
                kcContext.auth !== undefined &&
                kcContext.auth.showUsername &&
                !kcContext.auth.showResetCredentials
            ) ? (
                displayRequiredFields ? (
                    <div className={cx(kcProps.kcContentWrapperClass)}>
                        <div className={cx(kcProps.kcLabelWrapperClass, "subtitle")}>
                            <span className="subtitle">
                                <span className="required">*</span>
                                {msg("requiredFields")}
                            </span>
                        </div>
                        <div className="col-md-10">
                            <Text className={classes.root} typo="section heading">
                                {headerNode!}
                            </Text>
                        </div>
                    </div>
                ) : (
                    <Text className={classes.root} typo="section heading">
                        {headerNode!}
                    </Text>
                )
            ) : displayRequiredFields ? (
                <div className={cx(kcProps.kcContentWrapperClass)}>
                    <div className={cx(kcProps.kcLabelWrapperClass, "subtitle")}>
                        <span className="subtitle">
                            <span className="required">*</span> {msg("requiredFields")}
                        </span>
                    </div>
                    <div className="col-md-10">
                        {showUsernameNode}
                        <div className={cx(kcProps.kcFormGroupClass)}>
                            <div id="kc-username">
                                <label id="kc-attempted-username">
                                    {kcContext.auth?.attemptedUsername}
                                </label>
                                <a
                                    id="reset-login"
                                    href={kcContext.url.loginRestartFlowUrl}
                                >
                                    <div className="kc-login-tooltip">
                                        <i className={cx(kcProps.kcResetFlowIcon)}></i>
                                        <span className="kc-tooltip-text">
                                            {msg("restartLoginTooltip")}
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {showUsernameNode}
                    <div className={cx(kcProps.kcFormGroupClass)}>
                        <div id="kc-username">
                            <label id="kc-attempted-username">
                                {kcContext.auth?.attemptedUsername}
                            </label>
                            <a id="reset-login" href={kcContext.url.loginRestartFlowUrl}>
                                <div className="kc-login-tooltip">
                                    <i className={cx(kcProps.kcResetFlowIcon)}></i>
                                    <span className="kc-tooltip-text">
                                        {msg("restartLoginTooltip")}
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                </>
            )}
            {kcContext.realm.internationalizationEnabled &&
                supportedLanguageTags.length > 1 && (
                    <Select
                        borderLess={true}
                        menuSize={"Small"}
                        items={supportedLanguageTags.map(supportedLanguageTag => ({
                            id: supportedLanguageTag,
                            label: supportedLanguageTag,
                            icon: getLanguageFlag(supportedLanguageTag),
                        }))}
                        icon={getLanguageFlag(currentLanguageTag)}
                        selectedItems={[
                            {
                                id: currentLanguageTag,
                                label: currentLanguageTag,
                                icon: getLanguageFlag(currentLanguageTag),
                            },
                        ]}
                        onSelect={languageTag => {
                            window.location.replace(
                                window.location.origin +
                                    supportedLanguageTagUrl[languageTag.id],
                            );
                        }}
                        className={classes.languageSelect}
                    />
                )}
        </header>
    );
});

const useStyles = makeStyles()(() => ({
    "root": {
        "textAlign": "center",
        "fontSize": "16px",
        "marginBottom": "6px",
    },
    "languageSelect": {
        "width": "115px",
        "direction": "ltr",
    },
    "header": {
        "display": "flex",
        "alignItems": "flex-end",
        "justifyContent": "space-between",
    },
}));
