import { useEffect, memo } from "react";
import { useSplashScreen } from "onyxia-ui";
import { defaultKcProps } from "keycloakify";
import { makeStyles } from "ui/theme";
import { Login } from "./Login";

import { RegisterUserProfile } from "./RegisterUserProfile";
import { getBrowser } from "ui/tools/getBrowser";
import type { KcContext } from "./kcContext";
import { KcApp as KcAppBase } from "keycloakify/lib/components/KcApp";
import { Error } from "./Error";
import { Expire } from "./Expire";
import { ResetPassword } from "./ForgotPasswordSteps/ResetPassword";
import { UpdatePassword } from "./ForgotPasswordSteps/UpdatePassword";

export type Props = {
    kcContext: KcContext;
};

export const KcApp = memo((props: Props) => {
    const { kcContext } = props;

    const { hideRootSplashScreen } = useSplashScreen({
        "fadeOutDuration": getBrowser() === "firefox" ? 0 : undefined,
    });

    useEffect(() => {
        hideRootSplashScreen();
    }, []);

    const { classes } = useStyles();

    const kcProps = {
        ...defaultKcProps,
        "kcHtmlClass": [...defaultKcProps.kcHtmlClass, classes.kcHtmlClass],
        "kcLoginClass": [...defaultKcProps.kcLoginClass, classes.kcLoginClass],
        "kcFormCardClass": [...defaultKcProps.kcFormCardClass, classes.kcFormCardClass],
        "kcButtonPrimaryClass": [
            ...defaultKcProps.kcButtonPrimaryClass,
            classes.kcButtonPrimaryClass,
        ],
        "kcInputClass": [...defaultKcProps.kcInputClass, classes.kcInputClass],
    };
    switch (kcContext.pageId) {
        case "login.ftl":
            return <Login {...{ kcContext, ...kcProps }} />;
        case "register-user-profile.ftl":
            return <RegisterUserProfile {...{ kcContext, ...kcProps }} />;
        case "login-reset-password.ftl":
            return (
                <ResetPassword
                    {...{
                        kcContext,
                        ...kcProps,
                    }}
                />
            );
        case "login-update-password.ftl":
            return (
                <UpdatePassword
                    {...{
                        kcContext,
                        ...kcProps,
                    }}
                />
            );
        case "error.ftl":
            return <Error {...{ kcContext, ...kcProps }} />;
        case "login-page-expired.ftl":
            return <Expire {...{ kcContext, ...kcProps }} />;
        default:
            return <KcAppBase {...{ kcContext, ...kcProps }} />;
    }
});

const useStyles = makeStyles({ "name": { KcApp } })(theme => ({
    "kcLoginClass": {
        "& #kc-locale": {
            "zIndex": 5,
        },
    },
    "kcHtmlClass": {
        "& body": {
            "background": `#f9f9f9`,
            "fontFamily": theme.typography.fontFamily,
        },
        "background": `${theme.colors.useCases.surfaces.background}`,
        "& a": {
            "color": `${theme.colors.useCases.typography.textFocus}`,
        },
        "& #kc-current-locale-link": {
            "color": `${theme.colors.palette.light.greyVariant3}`,
        },
        "& label": {
            "fontSize": 14,
            "color": theme.colors.palette.light.greyVariant3,
            "fontWeight": "normal",
        },
        "& #kc-page-title": {
            ...theme.typography.variants["page heading"].style,
            "color": theme.colors.palette.dark.main,
        },
        "& #kc-header-wrapper": {
            "visibility": "hidden",
        },
    },
    "kcFormCardClass": {
        "borderRadius": 10,
    },
    "kcButtonPrimaryClass": {
        "backgroundColor": "unset",
        "backgroundImage": "unset",
        "borderColor": `${theme.colors.useCases.typography.textFocus}`,
        "borderWidth": "2px",
        "borderRadius": `20px`,
        "color": `${theme.colors.useCases.typography.textFocus}`,
        "textTransform": "uppercase",
    },
    "kcInputClass": {
        "borderRadius": "unset",
        "border": "unset",
        "boxShadow": "unset",
        "borderBottom": `1px solid ${theme.colors.useCases.typography.textTertiary}`,
        "&:focus": {
            "borderColor": "unset",
            "borderBottom": `1px solid ${theme.colors.useCases.typography.textFocus}`,
        },
    },
}));
