import { IR, US, AE, DE, NO, RU, SV } from "country-flag-icons/react/3x2";

const style = {
    width: "20px",
};

const tagFlagDictionary: Record<string, any> = {
    fa: <IR style={style} />,
    en: <US style={style} />,
    ar: <AE style={style} />,
    de: <DE style={style} />,
    no: <NO style={style} />,
    ru: <RU style={style} />,
    sv: <SV style={style} />,
} as const;

export const getLanguageFlag = (languageTag: string): any => {
    return tagFlagDictionary[languageTag];
};
