import React, { useState, useRef, useEffect, createRef } from "react";
import styled, { css } from "styled-components";
import { useClickOutside } from "@avidkit/hooks";
import PasswordStrengthBar from "react-password-strength-bar";
import { ReactComponent as EyeSvg } from "ui/assets/svg/eye-svgrepo-com.svg";

type IStartAdornment = (
    hover: boolean,
    typingMode: boolean,
    isFilledMode: boolean,
) => JSX.Element;
export interface InputProps
    extends Omit<
        React.DetailedHTMLProps<
            React.InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
        >,
        "onChange" | "ref" | "type"
    > {
    value: string;
    errorText?: string;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    onChange: (val: string) => void;
    startAdornment?: JSX.Element | IStartAdornment;
    className?: string;
    showStrength?: boolean;
}

export const Password = ({ disabled = false, ...props }: InputProps) => {
    const [contentVisibility, setContentVisibility] = useState(false);
    const containerRef = createRef<HTMLDivElement>();
    const [typingMode, setTypingMode] = useState(false);
    const disableTypingMode = () => setTypingMode(false);

    const inputRef = useRef<HTMLInputElement>();
    const onInputContainerClicked = () => inputRef?.current?.focus();

    useClickOutside(containerRef, disableTypingMode);

    const isFilledMode = props.value !== "" && !typingMode;
    useEffect(() => {
        if (!isFilledMode && props.value === "") {
            containerRef.current?.blur();
            inputRef.current?.blur();
            disableTypingMode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFilledMode]);

    return (
        <div className={props.className}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {!!props.label && (
                    <Label isLighten={typingMode || isFilledMode}>{props.label}</Label>
                )}
                {props.showStrength && (
                    <PasswordStrengthBar
                        password={props.value}
                        style={{
                            width: "41px",
                            position: "relative",
                            right: props.dir === "rtl" ? undefined : "8px",
                            left: props.dir === "rtl" ? "6px" : undefined,
                        }}
                        scoreWordStyle={{
                            display: "none",
                        }}
                    />
                )}
            </div>
            <InputContainer
                ref={containerRef}
                disabled={disabled}
                hasContent={!!props.value}
                onClick={onInputContainerClicked}
                isFilledMode={isFilledMode}
                isTypingMode={typingMode}
                hasError={!!props.errorText}
            >
                <TextInput
                    {...props}
                    type={contentVisibility ? "text" : "password"}
                    disabled={disabled}
                    onFocus={() => setTypingMode(true)}
                    ref={inputRef as any}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={(e: any) => props.onChange(e.target.value)}
                />
                <EyeSvg
                    onClick={() => setContentVisibility(!contentVisibility)}
                    style={{ height: "100%" }}
                />
            </InputContainer>
            {props.errorText && <Error>{props.errorText}</Error>}
        </div>
    );
};

export default Password;

interface InputContainerProps {
    hasError: boolean;
    hasContent: boolean;
    isFilledMode: boolean;
    isTypingMode: boolean;
    disabled: boolean;
}

const InputContainer = styled.div<InputContainerProps>`
    background-color: ${({ theme, hasContent }) =>
        hasContent ? theme.palette.grey[900] : theme.palette.grey[800]};
    border: 1px solid
        ${({ theme, hasContent, hasError }) =>
            hasError
                ? theme.palette.error.main
                : hasContent
                ? theme.palette.info.main
                : theme.palette.grey[800]};
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    padding: 14px;
    align-items: center;
    display: flex;
    position: relative;

    & > svg {
        margin-right: 6px;
    }

    & > svg path {
        width: 20px;
        height: 20px;
        stroke: ${({ theme }) => theme.palette.grey[400]};
        fill: ${({ theme }) => theme.palette.grey[400]};
    }
    ${({ disabled, theme }) =>
        !disabled &&
        css`
            &:hover {
                cursor: pointer;
                background-color: ${theme.palette.grey[900]};
                border: 1px solid ${theme.palette.info.main};
            }
        `}

    ${({ isTypingMode, hasError, theme }) =>
        isTypingMode &&
        css`
            box-shadow: 0 0 0 3px ${theme.palette.secondary.light};
            background-color: ${theme.palette.grey[900]};
            border: 1px solid
                ${hasError ? theme.palette.error.main : theme.palette.info.main};
        `}

  ${({ isFilledMode, disabled, hasError, theme }) =>
        isFilledMode &&
        !disabled &&
        css`
            border: 1px solid
                ${hasError ? theme.palette.error.main : theme.palette.grey[200]};
        `}

  ${({ disabled, theme }) =>
        disabled &&
        css`
            border: 1px solid ${theme.palette.grey[400]};
            background-color: ${theme.palette.grey[800]};
        `}
`;
const TextInput = styled.input`
    flex-grow: 1;
    background-color: transparent;
    height: 20px;
    border: none;
    outline: none;

    &::placeholder {
        color: ${({ theme }) => theme.palette.grey[300]};
    }
    &:disabled {
        color: ${({ theme }) => theme.palette.grey[200]};
    }
`;

const Error = styled.span`
    margin-top: 12px;
    display: inline-block;
    color: ${({ theme }) => theme.palette.error.main};
`;

interface LabelProps {
    isLighten: boolean;
}

const Label = styled.div<LabelProps>`
    color: ${({ theme, isLighten }) =>
        isLighten ? theme.palette.grey[200] : theme.palette.grey[100]};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
`;
