const dictionary = {
    about: {
        fr: "A propos",
        en: "About",
    },
    cluster: {
        fr: "Mon cluster",
        en: "My cluster",
    },
};

export default dictionary;
