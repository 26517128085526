const dictionary = {
    deleteAllServices: {
        fr: `Vous êtes sur le point de supprimer tous vos services. 
            Les données associées seront perdues.`,
        en: `You are about to delete all your services.
            You will lose all data associated to them.`,
    },
    noService: {
        fr: `Vous n'avez aucun service. Cliquer sur le bouton plus pour en créer.`,
        en: `You do not have service. Click the plus button to create.`,
    },
    myServicesDialogTitle: {
        fr: "Suppression de vos services",
        en: "Services deletion",
    },
    myServicesDialogSubtitle: { fr: "Attention !", en: "Caution!" },
    myServicesDialogBody: {
        fr: "Vous êtes sur le point de supprimer vos services",
        en: "You are about to delete your services",
    },
    myServicesDialogWarn: {
        fr: "Cette action entraînera la perte potentielle de données et/ou de code",
        en: "This action will result in the potential loss of data and/or code",
    },
    myServiceDialogTitle: {
        fr: "Suppression de votre service",
        en: "Service deletion",
    },
    myServiceDialogSubtitle: { fr: "Attention !", en: "Caution!" },
    myServiceDialogBody: {
        fr: "Vous êtes sur le point de supprimer votre service",
        en: "You are about to delete your service",
    },
    myServiceDialogWarn: {
        fr: "Cette action entraînera la perte potentielle de données et/ou de code",
        en: "This action will result in the potential loss of data and/or code",
    },
    serviceNotFound: { fr: "Service introuvable", en: "Service not found" },
    getPassword: {
        fr: "Obtenir le mot de passe des services",
        en: "Get password",
    },
    passwordCopiedToClipboard: {
        fr: "Mot de passe copié dans le presse-papier",
        en: "Password copied to clipboard",
    },
};

export default dictionary;
