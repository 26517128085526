import { RealmConfig } from "../Type";
import LoginImage from "ui/assets/svg/Login.svg";
import RegisterImage from "ui/assets/svg/Register.svg";
import ResetPasswordImage from "ui/assets/svg/ResetPassword.svg";
import UpdatePasswordImage from "ui/assets/svg/UpdatePassword.svg";

export const defaultConfig: RealmConfig = {
    extraFields: {},
    messages: {
        loginRightSectionHeader: {
            fa: "وارد شوید",
            en: "Let's Login",
        },
        loginRightSectionText: {
            fa: "با ورود به سامانه، میتوانید به تمامی سرویس های ارائه شده دسترسی پیدا کنید",
            en: "With Logging In You Can Access All Of The Services We Provide",
        },
        registerRightSectionHeader: {
            fa: "ثبت نام کنید",
            en: "Let's Register",
        },
        registerRightSectionText: {
            fa: "امکان دسترسی هر کاربر به تمامی سرویس های یک سازمان، با عنوان کاربری و کلمه عبور اختصاصی و یکسان",
            en: "ability to access organization services with unique username and password",
        },
        forgotPasswordRightSectionHeader: {
            en: "",
            fa: "",
        },
        forgotPasswordRightSectionText: {
            en: "",
            fa: "",
        },
    },
    termsLink: "https://notionwave.com/terms-and-conditions",
    logo: "realmName",
    pages: {
        login: {
            leftToRightRatio: 0.9935,
            rightSectionHeader: "loginRightSectionHeader",
            rightSectionText: "loginRightSectionText",
            rightSectionImage: LoginImage,
        },
        register: {
            leftToRightRatio: 1.9783,
            rightSectionHeader: "registerRightSectionHeader",
            rightSectionText: "registerRightSectionText",
            rightSectionImage: RegisterImage,
        },
        resetPassword: {
            leftToRightRatio: 0.9935,
            rightSectionHeader: "forgotPasswordRightSectionHeader",
            rightSectionText: "forgotPasswordRightSectionText",
            rightSectionImage: ResetPasswordImage,
        },
        updatePassword: {
            leftToRightRatio: 0.9935,
            rightSectionHeader: "forgotPasswordRightSectionHeader",
            rightSectionText: "forgotPasswordRightSectionText",
            rightSectionImage: UpdatePasswordImage,
        },
    },
    palette: {
        onyxia: {
            focus: {
                main: "#777",
                light: "#777",
                light2: "#777",
            },
            dark: {
                main: "#000",
                light: "#000",
                greyVariant1: "#000",
                greyVariant2: "#000",
                greyVariant3: "#000",
                greyVariant4: "#bcbdbf",
            },
            light: {
                main: "#000",
                light: "#000",
                greyVariant1: "#000",
                greyVariant2: "#000",
                greyVariant3: "#000",
                greyVariant4: "#bcbdbf",
            },
            redError: {
                main: "#e94d4d",
                light: "#fdeeee",
            },
            greenSuccess: {
                main: "#43c3a3",
                light: "#edf9f6",
            },
            orangeWarning: {
                main: "#e94d4d",
                light: "#fdeeee",
            },
            blueInfo: {
                main: "#09b6c7",
                light: "#e6f7f9",
            },
            "limeGreen": {
                "main": "#09b6c7",
                "light": "#00d1ab",
            },
            "agentConnectBlue": {
                "main": "#0579EE",
                "light": "#2E94FA",
                "lighter": "#E5EDF5",
            },
        },
        main: {
            primary: {
                main: "#d6d6d8",
                dark: "#0a4fde",
                "100": "#558afa",
                "200": "#8db0fb",
                light: "#c7d8fd",
            },
            secondary: {
                main: "#04bfbf",
                dark: "#06a1a1",
                "100": "#43cfcf",
                "200": "#81dfdf",
                light: "#c0efef",
            },
            success: {
                main: "#1ab297",
                dark: "#0a967d",
                "100": "#53c5b0",
                "200": "#8dd9cb",
                light: "#c6ece5",
            },
            info: {
                main: "#03b9ff",
                dark: "#00a5e5",
                "100": "#42cbff",
                "200": "#81dcff",
                light: "#c0eeff",
            },
            error: {
                main: "#f36d53",
                dark: "#dc553b",
                "100": "#f6927e",
                "200": "#f8b5a8",
                light: "#fcdad4",
            },
            warning: {
                main: "#f5d650",
                dark: "#ecc622",
                "100": "#f7e07b",
                "200": "#faeaa7",
                light: "#fcf4d4",
            },
            grey: {
                "100": "#21242a",
                "200": "#7b818b",
                "300": "#a1b1c7",
                "400": "#c1cddd",
                "500": "#d5dfed",
                "600": "#e3ebf7",
                "700": "#d6d6d8",
                "800": "#f7fafe",
                "900": "#fff",
            },
        },
    },
};
