/* eslint-disable jsx-a11y/anchor-is-valid */
import { useReducer, useEffect, memo } from "react";
import type { ReactNode } from "react";
import type { KcTemplateProps } from "keycloakify";
import { makeStyles } from "ui/theme";
import { useDomRect, useWindowInnerSize } from "onyxia-ui";
import { headInsert } from "keycloakify/lib/tools/headInsert";
import type { KcContext } from "../kcContext";
import { pathJoin } from "keycloakify/lib/tools/pathJoin";
import { TemplatePage } from "./Page";

export type TemplateProps = {
    isLogin: boolean;
    doFetchDefaultThemeResources: boolean;
    className?: string;
    displayInfo?: boolean;
    displayMessage?: boolean;
    displayRequiredFields?: boolean;
    displayWide?: boolean;
    showAnotherWayIfPresent?: boolean;
    headerNode: ReactNode;
    showUsernameNode?: ReactNode;
    formNode: ReactNode;
    infoNode?: ReactNode;
    onClickCross?(): void;
    leftToRightRatio: number;
    rightSectionHeader: string;
    rightSectionText: string;
    rightSectionImage: string;
    logoImage: string;
} & { kcContext: KcContext } & KcTemplateProps;

const useStyles = makeStyles<{
    windowInnerWidth: number;
    aspectRatio: number;
    windowInnerHeight: number;
    stickToTop: boolean;
}>()((_theme, { stickToTop }) => ({
    "root": {
        "height": "100vh",
        "display": "flex",
        "flexDirection": "column",
        "backgroundColor": "#f9f9f9",
    },
    "betweenHeaderAndFooter": {
        "flex": 1,
        "overflow": "hidden",
        "background": `#f9f9f9`,
    },
    "page": {
        "height": "100%",
        "overflow": "auto",
        "@media (max-width: 1000px)": {
            "display": stickToTop ? "block" : "flex",
        },
    },
}));

export const Template = memo((props: TemplateProps) => {
    const { kcContext, className, doFetchDefaultThemeResources, onClickCross, isLogin } =
        props;

    const { ref: rootRef } = useDomRect();

    const { windowInnerWidth, windowInnerHeight } = useWindowInnerSize();

    const { classes, cx } = useStyles({
        windowInnerWidth,
        "aspectRatio": windowInnerWidth / windowInnerHeight,
        windowInnerHeight,
        stickToTop: !isLogin,
    });

    const [isExtraCssLoaded, setExtraCssLoaded] = useReducer(() => true, false);

    useEffect(() => {
        if (!doFetchDefaultThemeResources) {
            setExtraCssLoaded();
            return;
        }

        let isUnmounted = false;
        const cleanups: (() => void)[] = [];

        const toArr = (x: string | readonly string[] | undefined) =>
            typeof x === "string" ? x.split(" ") : x ?? [];

        Promise.all(
            [
                ...toArr(props.stylesCommon).map(relativePath =>
                    pathJoin(kcContext.url.resourcesCommonPath, relativePath),
                ),
                ...toArr(props.styles).map(relativePath =>
                    pathJoin(kcContext.url.resourcesPath, relativePath),
                ),
            ].map(href =>
                headInsert({
                    "type": "css",
                    href,
                    "position": "prepend",
                }),
            ),
        ).then(() => {
            if (isUnmounted) {
                return;
            }

            setExtraCssLoaded();
        });

        toArr(props.scripts).forEach(relativePath =>
            headInsert({
                "type": "javascript",
                "src": pathJoin(kcContext.url.resourcesPath, relativePath),
            }),
        );

        if (props.kcHtmlClass !== undefined) {
            const htmlClassList = document.getElementsByTagName("html")[0].classList;

            const tokens = cx(props.kcHtmlClass).split(" ");

            htmlClassList.add(...tokens);

            cleanups.push(() => htmlClassList.remove(...tokens));
        }

        return () => {
            isUnmounted = true;

            cleanups.forEach(f => f());
        };
    }, [props.kcHtmlClass]);

    if (!isExtraCssLoaded) {
        return null;
    }

    return (
        <div ref={rootRef} className={cx(classes.root, className)}>
            <section className={classes.betweenHeaderAndFooter}>
                <TemplatePage
                    {...props}
                    className={classes.page}
                    onClickCross={onClickCross}
                />
            </section>
        </div>
    );
});
