/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo } from "react";
import type { ReactNode } from "react";
import { getMsg } from "keycloakify";
import { useConstCallback } from "powerhooks/useConstCallback";
import type { KcTemplateProps } from "keycloakify";
import { makeStyles } from "ui/theme";
import type { KcContext } from "../../kcContext";

type TemplateMainProps = {
    displayMessage?: boolean;
    formNode: ReactNode;
    showAnotherWayIfPresent?: boolean;
    displayWide?: boolean;
    displayInfo?: boolean;
    infoNode?: ReactNode;
    style?: React.CSSProperties;
} & { kcContext: KcContext } & KcTemplateProps;

export const TemplateMain = memo((props: TemplateMainProps) => {
    const {
        displayMessage,
        showAnotherWayIfPresent,
        displayInfo,
        displayWide,
        kcContext,
        formNode,
        infoNode,
        style,
        ...kcProps
    } = props;

    const onTryAnotherWayClick = useConstCallback(() => {
        document.forms["kc-select-try-another-way-form" as never].submit();
        return false;
    });

    const { msg } = getMsg(kcContext);

    const { cx } = useStyles();

    return (
        <div id="kc-content">
            <div id="kc-content-wrapper">
                {formNode}
                {kcContext.auth !== undefined &&
                    kcContext.auth.showTryAnotherWayLink &&
                    showAnotherWayIfPresent && (
                        <form
                            id="kc-select-try-another-way-form"
                            action={kcContext.url.loginAction}
                            method="post"
                            className={cx(displayWide && props.kcContentWrapperClass)}
                        >
                            <div
                                className={cx(
                                    displayWide && [
                                        kcProps.kcFormSocialAccountContentClass,
                                        kcProps.kcFormSocialAccountClass,
                                    ],
                                )}
                            >
                                <div className={cx(kcProps.kcFormGroupClass)}>
                                    <input
                                        type="hidden"
                                        name="tryAnotherWay"
                                        value="on"
                                    />
                                    <a
                                        href="#"
                                        id="try-another-way"
                                        onClick={onTryAnotherWayClick}
                                    >
                                        {msg("doTryAnotherWay")}
                                    </a>
                                </div>
                            </div>
                        </form>
                    )}
                {displayInfo && (
                    <div id="kc-info" className={cx(kcProps.kcSignUpClass)}>
                        <div
                            id="kc-info-wrapper"
                            className={cx(kcProps.kcInfoAreaWrapperClass)}
                        >
                            {infoNode}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

const useStyles = makeStyles()(() => ({}));
