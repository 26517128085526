/* eslint-disable react-hooks/exhaustive-deps */
import { createRef, RefObject, useEffect, useRef, useState } from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { concat, uniq, xorBy } from "lodash";
import { Item, Menu } from "@avidkit/menu";
import { useClickOutside, useScroll } from "@avidkit/hooks";
import Selector from "./Select/Selector";
import InputLabel from "./Select/InputLabel";

export interface SelectProps {
    items: Item[];
    label?: string;
    loading?: boolean;
    icon?: JSX.Element;
    selectedItems: Item[];
    borderLess?: boolean;
    disabled?: boolean;
    errorText?: string;
    isOptional?: boolean;
    placeHolder?: string;
    multiSelectable?: boolean;
    onLoadMoreItems?: () => void;
    controllerIcon?: JSX.Element;
    onControllerClick?: () => void;
    renderInput?: () => JSX.Element;
    customStyle?: FlattenSimpleInterpolation;
    rendererRef?: RefObject<HTMLInputElement>;
    className?: string;
    onSelect: (item: Item, itemRef?: HTMLDivElement) => void;
    menuSize: "Small" | "Big";
}

export const Select = (props: SelectProps) => {
    const isDisabled = props.disabled || false;
    const menuRef = useRef<HTMLInputElement>(null);
    const containerRef = createRef<HTMLDivElement>();
    const [showMenu, setShowMenu] = useState(false);

    const menuItemEls = useRef<Record<string, HTMLDivElement | null>>({});
    const isOpen = !isDisabled && showMenu;
    const hasFilterRenderer = !!props.renderInput;
    const isFilled = props.selectedItems.length > 0;
    const selectorText = props.selectedItems.map(item => item.label).join(", ");

    const sortedItems =
        props.multiSelectable && hasFilterRenderer
            ? concat(props.selectedItems, xorBy(props.items, props.selectedItems, "id"))
            : props.items;

    const { hitThreshold } = useScroll(menuRef ?? null, "80%");

    useClickOutside(containerRef, () => setShowMenu(false));

    const onToggle = () => {
        if (
            !isDisabled &&
            (!props.multiSelectable || !showMenu) &&
            (!hasFilterRenderer || !props.multiSelectable || !showMenu)
        ) {
            setShowMenu(!showMenu);
        }
    };

    const onSelectHandler = (item: any) => {
        props.onSelect(item, menuItemEls.current[item] ?? undefined);
    };

    useEffect(() => {
        setTimeout(() => showMenu && props.rendererRef?.current?.focus(), 500);
    }, [showMenu]);

    useEffect(() => {
        if (isDisabled) {
            setShowMenu(false);
        }
    }, [isDisabled]);

    useEffect(() => {
        if (hitThreshold) {
            props.onLoadMoreItems?.();
        }
    }, [hitThreshold]);

    return (
        <Container
            $customStyle={props.customStyle}
            className={props.className}
            ref={containerRef}
            onClick={onToggle}
        >
            {props.label && <InputLabel {...props} isFilled={isFilled} />}
            <Selector
                {...props}
                placeHolder={props.placeHolder}
                borderLess={props.borderLess}
                isOpen={isOpen}
                isFilled={isFilled}
                isFocused={showMenu}
                hasError={!!props.errorText}
                selectedValue={selectorText}
                onControllerClick={props.onControllerClick}
                controllerIcon={props.controllerIcon}
            />
            {isOpen && (
                <StyledMenu
                    size={props.menuSize}
                    menuRef={menuRef}
                    items={uniq(sortedItems)}
                    menuItemsRef={menuItemEls}
                    onSelect={onSelectHandler}
                    selectedItems={props.selectedItems}
                    multiSelectable={props.multiSelectable}
                    emptyTitle={props.renderInput && "No results found!"}
                />
            )}
            {props.errorText && <Error>{props.errorText}</Error>}
        </Container>
    );
};

interface ContainerProps {
    $customStyle?: FlattenSimpleInterpolation;
}

const Container = styled.div<ContainerProps>`
    cursor: pointer;
    position: relative;
    ${({ $customStyle }) => $customStyle}
`;

const Error = styled.div`
    margin-top: 12px;
    color: ${({ theme }) => theme.palette.error.main};
`;

const StyledMenu = styled(Menu)`
    width: 100%;
    position: absolute;
    box-sizing: border-box;
`;
