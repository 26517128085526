/* 
* Automatically generated by cra-envs.
* If you wish to declare a new environment variable declare it in the .env file (prefixed by REACT_APP_)
* then run 'npx generate-env-getter' at the root of your project.
* This file will be updated.
*/
import { getEnvVarValue } from "cra-envs";

export const envNames = [
    "ONYXIA_API_URL",
    "KEYCLOAK_URL",
    "KEYCLOAK_CLIENT_ID",
    "KEYCLOAK_REALM",
    "JWT_EMAIL_CLAIM",
    "JWT_FAMILY_NAME_CLAIM",
    "JWT_FIRST_NAME_CLAIM",
    "JWT_USERNAME_CLAIM",
    "JWT_LOCALE_CLAIM",
    "JWT_GROUPS_CLAIM",
    "TERMS_OF_SERVICES",
    "VAULT_URL",
    "VAULT_KV_ENGINE",
    "VAULT_ROLE",
    "VAULT_KEYCLOAK_URL",
    "VAULT_KEYCLOAK_CLIENT_ID",
    "VAULT_KEYCLOAK_REALM",
    "HIGHLIGHTED_PACKAGES",
    "THEME_ID",
    "HEADER_ORGANIZATION",
    "HEADER_USECASE_DESCRIPTION",
    "HEADER_HIDE_ONYXIA",
    "DISABLE_HOME_PAGE",
    "EXTRA_LEFTBAR_ITEMS",
    "HEADER_LINKS",
    "DESCRIPTION"
] as const;

export type EnvNames = typeof envNames[number];

let env: Record<EnvNames, string> | undefined = undefined;

export function getEnv() {

    if (env === undefined) {
        env = {} as Record<EnvNames, string>;
        for (const envName of envNames) {
            env[envName] = getEnvVarValue(envName);
        }
    }

    return env;

}
