const dictionary = {
    btnLogin: {
        fr: "login",
        en: "login",
    },
    btnClose: {
        fr: "fermer",
        en: "close",
    },
    btnDiscover: {
        fr: "Découvrez",
        en: "Discover",
    },
    btnSaveAsLabel: {
        fr: "Sauvegarder sous",
        en: "Save as",
    },
    btnCopyLabel: {
        fr: "Copier dans le presse papier",
        en: "Copy to clipboard",
    },
    btnNext: {
        fr: "suivant",
        en: "next",
    },
    btnPrevious: {
        fr: "précédent",
        en: "previous",
    },
    btnSelfServiceCatalog: {
        fr: "catalogue du libre service",
        en: "self service catalog",
    },
    btnSelfServiceCreation: {
        fr: "création du service",
        en: "self service creation",
    },
    btnMyLab: {
        fr: "mon labo",
        en: "my lab",
    },
    btnDetails: {
        fr: "details",
        en: "details",
    },
    btnValid: { fr: "Valider", en: "Valid" },
    btnCancel: { fr: "Annuler", en: "Cancel" },
};

export default dictionary;
