import { KcContextBase } from "keycloakify";
import { defaultConfig } from "./default";
import { namazConfig } from "./Namaz";
import { pubnitoConfig } from "./pubnito";
import { scinitoConfig } from "./scinito";
import { trainingConfig } from "./training";
import { BatchConfig } from "./Type";
import { scinitoAIConfig } from "./scinitoai";

const batchConfig: BatchConfig = {
    "origin": {
        "https://nightly.accounts.pubnito.com": {
            "realm": {
                "Accounts": scinitoConfig,
            },
        },
        "https://accounts.pubnito.com": {
            "realm": {
                "Pubnito": pubnitoConfig,
            },
        },
        "https://accounts.islamicdl.com": {
            "realm": {
                "Namaz": namazConfig,
            },
        },
        "https://accounts.scinito.com": {
            "realm": {
                "Scinito": scinitoConfig,
            },
        },
        "https://accounts.iknito.com": {
            "realm": {
                "Training": trainingConfig,
            },
        },
        "https://accounts.scinito.ai": {
            "realm": {
                "Scinitoai": scinitoAIConfig,
            },
        },

        "http://localhost:3000": {
            "realm": {
                "myrealm": defaultConfig,
            },
        },
    },
};

export const getConfig = (kcContext: KcContextBase.Common) => {
    return (
        batchConfig.origin[window.location.origin]?.realm?.[kcContext.realm.name] ??
        defaultConfig
    );
};
