import { getCurrentKcLanguageTag, KcContextBase } from "keycloakify";

export const detectDirection = (kcContext: KcContextBase.Common) => {
    // return 'rtl'
    const currentLanguageTag = getCurrentKcLanguageTag(kcContext);
    if (
        (currentLanguageTag as string) === "fa" ||
        (currentLanguageTag as string) === "ar"
    ) {
        return "rtl";
    } else {
        return "ltr";
    }
};
