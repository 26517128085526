/* in some situations(like dynamic and static input errors) we don't have a key to give it to
msgStr and get a related translated value to show, keycloak itself will return to us the error
with the language currently used, but we extended the languages(until now we have fa and ar)
and keycloak does know about these and their key values, in such situations it just works in
default and returns the messages in english even if you're in farsi for example, with this function
in such situations we get the english value and with a reverse english dictionary, we get the key
for that and then we give it to msgStr and get back the message in the proper language
Note: sometime keycloak stacks the messages together with <br> separation. so we do the process for
each of them and then join them together in the same way  */

import { getMsg, KcContextBase } from "keycloakify";
import reverseEnJson from "./en.reverse.json";

const reverseEn = reverseEnJson as Record<string, string>;

export const getReverseMessage = (kcContext: KcContextBase.Common, message: string) => {
    const { msgStr } = getMsg(kcContext);
    const result: string[] = [];
    const separatedMessages = message.split("<br>");
    for (const separatedMessage of separatedMessages) {
        if (
            kcContext.locale?.currentLanguageTag !== "en" &&
            reverseEn[separatedMessage] !== undefined
        ) {
            result.push(msgStr(reverseEn[separatedMessage] as any));
        } else {
            result.push(separatedMessage);
        }
    }
    return result.join("<br>");
};
