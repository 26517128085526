import { useState, useRef, memo } from "react";
import type { KcProps } from "keycloakify/lib/components/KcProps";
import { getCurrentKcLanguageTag, getMsg } from "keycloakify";
import { useConstCallback } from "powerhooks/useConstCallback";
import { Template } from "../Template/Template";
import Link from "@mui/material/Link";
import { makeStyles, Text } from "ui/theme";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "onyxia-ui/Checkbox";
import { LoginDivider } from "./LoginDivider";
import type { KcContext } from "../kcContext";
import { Button, Direction } from "@mui/material";
import { ReactComponent as GoogleSvg } from "ui/assets/svg/Google.svg";
import { ReactComponent as FacebookSvg } from "ui/assets/svg/Facebook.svg";
import { ReactComponent as TwitterSvg } from "ui/assets/svg/Twiter.svg";
import { ReactComponent as AppleSvg } from "ui/assets/svg/Apple.svg";
import { ReactComponent as GitHubSvg } from "ui/assets/svg/GitHub.svg";
import { getConfig } from "config/batch";
import { Input } from "@avidkit/input";
import { detectDirection } from "ui/tools/detectDirection";
import { Password } from "../Password";
import { Alert } from "@mui/material";
import { getReverseMessage } from "ui/extendKcMessages/getReverseMessage";

type KcContext_Login = Extract<KcContext, { pageId: "login.ftl" }>;

export const Login = memo(
    ({ kcContext, ...props }: { kcContext: KcContext_Login } & KcProps) => {
        const { msg, msgStr } = getMsg(kcContext);

        const [username, setUsername] = useState("");
        const [password, setPassword] = useState("");

        const {
            social,
            realm,
            url,
            usernameEditDisabled,
            login,
            auth,
            registrationDisabled,
        } = kcContext;

        const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);

        const submitButtonRef = useRef<HTMLButtonElement>(null);

        const onGoBack = useConstCallback(() => global.history.back());

        const onSubmit = useConstCallback(() => {
            setIsLoginButtonDisabled(true);
            return true;
        });

        const direction = detectDirection(kcContext);

        const providersLength = social.providers?.length || 0;

        const { classes } = useStyles({
            direction,
            providersLength,
            alertType: kcContext.message?.type,
        });

        const currentLanguageTag = getCurrentKcLanguageTag(kcContext);

        const { pages, logo, messages } = getConfig(kcContext);
        const pageConfig = pages.login;

        return (
            <Template
                {...{ kcContext, ...props, isLogin: true }}
                leftToRightRatio={pageConfig.leftToRightRatio}
                rightSectionHeader={
                    messages[pageConfig.rightSectionHeader][currentLanguageTag]
                }
                rightSectionText={
                    messages[pageConfig.rightSectionText][currentLanguageTag]
                }
                rightSectionImage={pageConfig.rightSectionImage}
                logoImage={logo}
                doFetchDefaultThemeResources={false}
                onClickCross={onGoBack}
                displayInfo={social.displayInfo}
                displayWide={realm.password && social.providers !== undefined}
                headerNode={msg("doLogIn")}
                formNode={
                    <div className={classes.root}>
                        {realm.password &&
                            realm.registrationAllowed &&
                            !registrationDisabled && (
                                <div className={classes.linkToRegisterWrapper}>
                                    <Text
                                        typo="body 2"
                                        color="disabled"
                                        className={classes.registerText}
                                    >
                                        {msg("noAccount")!}
                                    </Text>
                                    <Link
                                        href={url.registrationUrl}
                                        className={classes.registerLink}
                                        underline="hover"
                                    >
                                        {msgStr("doRegister")}
                                    </Link>
                                </div>
                            )}
                        <div className={classes.bannerPlaceHolder}>
                            {/* App-initiated actions should not see warning messages about the need to complete the action during login.*/}
                            {kcContext.message !== undefined &&
                                (kcContext.message.type !== "warning" ||
                                    !kcContext.isAppInitiatedAction) && (
                                    <Alert
                                        className={classes.alert}
                                        severity={kcContext.message.type}
                                    >
                                        <Text
                                            typo="label 2"
                                            className={classes.bannerText}
                                        >
                                            {getReverseMessage(
                                                kcContext,
                                                kcContext.message.summary,
                                            )}
                                        </Text>
                                    </Alert>
                                )}
                        </div>
                        <div>
                            {realm.password && (
                                <form
                                    onSubmit={onSubmit}
                                    action={url.loginAction}
                                    method="post"
                                    className={classes.form}
                                >
                                    <Input
                                        disabled={usernameEditDisabled}
                                        autoFocus
                                        tabIndex={1}
                                        id="username"
                                        name="username"
                                        inputProps_aria-label="username"
                                        label={
                                            !realm.loginWithEmailAllowed
                                                ? msgStr("username")
                                                : !realm.registrationEmailAsUsername
                                                ? msgStr("usernameOrEmail")
                                                : msgStr("email")
                                        }
                                        autoComplete="off"
                                        value={username}
                                        onChange={setUsername}
                                    />
                                    <Password
                                        dir={direction}
                                        tabIndex={2}
                                        id="password"
                                        name="password"
                                        inputProps_aria-label={"password"}
                                        label={msgStr("password")}
                                        autoComplete="off"
                                        value={password}
                                        onChange={setPassword}
                                    />
                                    <div
                                        className={
                                            classes.rememberMeForgotPasswordWrapper
                                        }
                                    >
                                        <div className={classes.forgotPassword}>
                                            {realm.resetPasswordAllowed && (
                                                <Link
                                                    href={url.loginResetCredentialsUrl}
                                                    underline="hover"
                                                    className={classes.forgotPasswordLink}
                                                >
                                                    {msg("doForgotPassword")}
                                                </Link>
                                            )}
                                        </div>
                                        <div className={classes.rememberMeWrapper}>
                                            {realm.rememberMe &&
                                                !usernameEditDisabled && (
                                                    <div className="checkbox">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    defaultChecked={
                                                                        !!login.rememberMe
                                                                    }
                                                                    name="rememberMe"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={
                                                                <Text
                                                                    typo="body 2"
                                                                    color="disabled"
                                                                >
                                                                    {msg("rememberMe")!}
                                                                </Text>
                                                            }
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    <div className={classes.buttonsWrapper}>
                                        <input
                                            type="hidden"
                                            name="credentialId"
                                            {...(auth?.selectedCredential !== undefined
                                                ? {
                                                      "value": auth.selectedCredential,
                                                  }
                                                : {})}
                                        />
                                        <Button
                                            ref={submitButtonRef}
                                            tabIndex={3}
                                            className={classes.buttonSubmit}
                                            name="login"
                                            type="submit"
                                            disabled={isLoginButtonDisabled}
                                        >
                                            {msgStr("doLogIn")}
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </div>
                        {realm.password && social.providers !== undefined && (
                            <>
                                <LoginDivider className={classes.divider} />
                                <div>
                                    <ul className={classes.providers}>
                                        {social.providers.map(p => {
                                            const providerLogo = (
                                                displayName: string,
                                            ) => {
                                                switch (displayName) {
                                                    case "Google":
                                                        return <GoogleSvg />;
                                                    case "Facebook":
                                                        return (
                                                            <FacebookSvg
                                                                style={{
                                                                    margin: "0 4px",
                                                                }}
                                                            />
                                                        );
                                                    case "Twitter":
                                                        return <TwitterSvg />;
                                                    case "Apple":
                                                        return <AppleSvg />;
                                                    case "GitHub":
                                                        return <GitHubSvg />;
                                                }
                                            };

                                            return (
                                                <li key={p.providerId}>
                                                    <Link
                                                        href={p.loginUrl}
                                                        className={classes.providerLink}
                                                    >
                                                        <div
                                                            className={
                                                                classes.providerLogoWrapper
                                                            }
                                                        >
                                                            {providerLogo(p.displayName)}
                                                        </div>
                                                        {providersLength < 3
                                                            ? `Login with ${p.displayName}`
                                                            : undefined}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>
                }
            />
        );
    },
);

export declare namespace Login {
    export type I18nScheme = {
        "doRegister": undefined;
    };
}

const useStyles = makeStyles<{
    direction: Direction;
    providersLength: number;
    alertType?: "success" | "warning" | "error" | "info";
}>({
    "name": { Login },
})((theme, { direction, providersLength, alertType }) => {
    const { blueInfo, greenSuccess, orangeWarning, redError } = theme.colors.palette;
    let alertColor: { main: string; light: string };
    switch (alertType) {
        case "info":
            alertColor = blueInfo;
            break;
        case "success":
            alertColor = greenSuccess;
            break;
        case "warning":
            alertColor = orangeWarning;
            break;
        case "error":
            alertColor = redError;
            break;
        default:
            alertColor = blueInfo;
    }
    return {
        "root": {
            "direction": direction,
            "& .MuiTextField-root": {
                "width": "100%",
                "marginTop": theme.spacing(5),
            },
        },
        "forgotPasswordLink": {
            color: "#bcbdbf",
        },
        "rememberMeForgotPasswordWrapper": {
            "display": "flex",
            "justifyContent": "space-between",
            "marginTop": "-14px",
        },
        "forgotPassword": {
            "display": "flex",
            "alignItems": "center",
        },
        "buttonsWrapper": {
            "marginTop": "-10px",
            "display": "flex",
            "justifyContent": "flex-end",
        },
        "buttonSubmit": {
            "fontSize": "18px",
            "textTransform": "none",
            "marginLeft": theme.spacing(2),
            "backgroundColor": theme.colors.palette.limeGreen.light,
            "opacity": "0.5",
            "borderRadius": "8px",
            "color": "white",
            "width": "100%",
            "height": "57px",
            "margin": 0,
            "padding": 0,
            "&:hover": {
                "backgroundColor": theme.colors.palette.limeGreen.light,
                "color": "white",
            },
            "&:disabled": {
                "backgroundColor": theme.colors.palette.limeGreen.light,
                "color": "white",
                "opacity": "0.2",
            },
        },
        "linkToRegisterWrapper": {
            "textAlign": "center",
            "display": "flex",
            "& > *": {
                "display": "inline-block",
            },
        },
        "bannerPlaceHolder": {
            "display": "flex",
            "alignItems": "center",
            "width": "100%",
            "height": "36px",
        },
        "bannerText": {
            "color": alertColor.main,
        },
        "rememberMeWrapper": {
            "direction": direction === "ltr" ? "rtl" : "ltr",
        },
        "registerText": {
            "fontSize": "14px",
            "marginTop": "2px",
            "color": "#7b818b",
        },
        "registerLink": {
            "paddingLeft": theme.spacing(2),
            "paddingRight": theme.spacing(2),
            "color": theme.colors.palette.limeGreen.main,
        },
        "divider": {
            "marginTop": "18px",
            "marginBottom": "13px",
        },
        "providers": {
            "listStyleType": "none",
            "padding": 0,
            "display": "flex",
            "justifyContent": "space-evenly",
        },
        "form": {
            "display": "grid",
            "gridTemplate": "auto / auto",
            "gridRowGap": "32px",
        },
        "providerLink": {
            "border": "solid 1px #d6d6d8",
            "borderRadius": "8px",
            "color": "#7b818b",
            "padding": "12px",
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "center",
        },
        "providerLogoWrapper": {
            "margin": providersLength < 3 ? "0 6.4px" : 0,
            "marginTop": "3px",
        },
        "alert": {
            "alignItems": "center",
            "backgroundColor": alertColor.light,
            "width": "100%",
            "direction": direction,
            "padding": "0 6px",
            "height": "20px",
        },
    };
});
